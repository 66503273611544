import classes from "./Common.module.scss";
import React, {useState} from "react";
import {useCollapse} from "react-collapsed";

const CustomerFields = ({customer, customerName, emailId}) => {
    const [isExpanded, setExpanded] = useState(true);
    const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

    function handleOnClick() {
        setExpanded(!isExpanded);
    }

    return (
        <div>
            <fieldset className={classes.fieldSet}>
                <legend className={classes.legend}> Customer Information </legend>
                <div className={classes.expand} {...getToggleProps({onClick: handleOnClick})}>
                    {isExpanded ? '-' : '+'}
                </div>
                <div {...getCollapseProps()}>
                    <div className={classes.div}>
                        <label className={classes.label}>Customer Name:</label>
                        <label className={classes.labelText}>{customerName}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Email ID:</label>
                        <label className={classes.labelText}>{emailId}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Billing Customer ID:</label>
                        <label className={classes.labelText}>{customer.id}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Stripe Invoice ID:</label>
                        <label className={classes.labelText}>{customer.externalBillingId}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Customer Market:</label>
                        <label
                            className={classes.labelText}>{customer.subscription?.metadata?.market}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Reservation ID:</label>
                        <label
                            className={classes.labelText}>{customer.subscription?.metadata?.reservationId}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Azure Subscriber ID:</label>
                        <label className={classes.labelText}>{customer.subscription?.metadata?.azureId}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Default Payment Method ID:</label>
                        <label
                            className={classes.labelText}>{customer.defaultPaymentMethodId}</label>
                    </div>
                </div>
            </fieldset>
        </div>
    );
}
export default CustomerFields;