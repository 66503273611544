import authGuard from "../../../shared/components/AuthGuard";
import * as billingService from "../../../services/BillingService";
import { useCustomerContext } from "../landingPage/useLandingPage";
import React, { useEffect, useState } from "react";
import { SubscriptionSupplementalPlanMap } from "../../../models/SubscriptionSupplementalPlanMap";
import classes from "../pendingInvoiceItem/PendingInvoiceItemPage.module.scss";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { BsFillTrashFill } from "react-icons/bs";
import AddonModal from "../../form/components/modal/addon/AddonModal";
import { currencyValueFormatter, formatDisplayDate } from "../../../utils/CommonUtils";
import RemoveAddonModal from "../../form/components/modal/addon/RemoveAddonModal";

const AddonPage = ({ disableVirtualization }) => {
    const state = useCustomerContext();
    const [error, setError] = useState(null);
    const [plans, setPlans] = useState<SubscriptionSupplementalPlanMap[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [removeModalData, setRemoveModalData] = useState<SubscriptionSupplementalPlanMap>(null);

    useEffect(() => {
        (async () => {
            await getAllSupplementalPlans();
        })();
    }, []);

    const onSubmit = async () => {
        await getAllSupplementalPlans();
    };

    const getAllSupplementalPlans = async () => {
        if (!state.subscriptionId) {
            setError(`Customer does not have a subscription`);
            return null;
        }

        try {
            const supplementalPlansResponse = await billingService.getSupplementalPlansBySubscription(state.subscriptionId);
            setPlans(supplementalPlansResponse.data.data);
        } catch (err) {
            setError(`Could not retrieve addons for subscription = ${state.subscriptionId}`);
        }
    };

    const currentDateIsBillingDate = (): boolean => {
        const currentDate = new Date().toISOString().split("T")[0];
        return currentDate === state.subscription?.currentBillingDate || currentDate === state.subscription?.nextBillingDate;
    };

    const onEndAddon = (plan: SubscriptionSupplementalPlanMap) => {
        if (currentDateIsBillingDate()) return;
        if (plan.endDate) return;
        setRemoveModalData(plan);
    };

    const columns: GridColDef[] = [
        { field: "supplementalGroupCode", headerName: "Plan Name", width: 150 },
        { field: "name", headerName: "Name", width: 300 },
        { field: "description", headerName: "Description", width: 300 },
        { field: "price", headerName: "Price", width: 150, valueFormatter: currencyValueFormatter },
        { field: "effectiveDate", headerName: "Effective Date", width: 150, valueFormatter: formatDisplayDate },
        { field: "endDate", headerName: "End Date", width: 150, valueFormatter: formatDisplayDate },
        { field: "supplementalPlanId", headerName: "Plan ID", width: 300 },
        {
            field: "end",
            headerName: "End",
            width: 100,
            renderCell: (params: GridRenderCellParams<any, Date>) => params.value,
            sortable: false,
        },
    ];

    const getAddonDisplayData = (plans: SubscriptionSupplementalPlanMap[]) => {
        return plans.map((plan, index) => {
            const supplementalPlan = state.supplementalPlans.data.find(
                (supplementalPlan) => supplementalPlan.id === plan.supplementalPlanId,
            );
            return {
                id: plan.supplementalPlanId,
                supplementalGroupCode: plan.supplementalGroupCode,
                name: supplementalPlan?.name,
                description: supplementalPlan?.description,
                price: supplementalPlan?.price,
                effectiveDate: plan.effectiveDate,
                endDate: plan.endDate,
                supplementalPlanId: plan.supplementalPlanId,
                end: <BsFillTrashFill className={classes.deleteBtn} data-testid={`end-${index}`} onClick={() => onEndAddon(plan)} />,
            };
        });
    };

    return (
        <div>
            <div className={classes.container}>
                <div className={classes.divAdd}>
                    <button
                        data-testid="add-supplemental-plan-test-id"
                        disabled={currentDateIsBillingDate()}
                        onClick={() => setModalOpen(true)}
                        className={`${classes.button} ${classes.enable_bg_color}`}
                    >
                        Add Plan
                    </button>
                </div>
                <div hidden={!currentDateIsBillingDate()} className={classes.deleteBtn}>
                    <p>You cannot request an add-on on the customer's invoicing date.</p>
                </div>
                {modalOpen && <AddonModal onSubmit={onSubmit} closeModal={() => setModalOpen(false)} />}
                {removeModalData && (
                    <RemoveAddonModal
                        onSubmit={() => onSubmit()}
                        closeModal={() => setRemoveModalData(null)}
                        supplementalPlanMap={removeModalData}
                    />
                )}
                <div className={classes.table}>
                    <DataGrid
                        rows={getAddonDisplayData(plans)}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10, 20, 50]}
                        disableVirtualization={disableVirtualization}
                        disableColumnSelector
                    />
                </div>
                <div hidden={!error} className={classes.deleteBtn}>
                    <p>{error}</p>
                </div>
            </div>
        </div>
    );
};

export default authGuard(AddonPage);
