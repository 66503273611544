import authGuard from "../../../../../shared/components/AuthGuard";
import classes from "../CreditsNoteModal.module.scss";
import { Box, Modal } from "@mui/material";
import { Button, Form } from "../../../FormComponents";
import React, { useState } from "react";
import { useCustomerContext } from "../../../../billingService/landingPage/useLandingPage";
import Select from "react-select";
import * as billingService from "../../../../../services/BillingService";
import { SupplementalPlan } from "../../../../../models/SupplementalPlan";

const AddonModal = ({ onSubmit, closeModal }) => {
    const state = useCustomerContext();
    const [supplementalPlan, setSupplementalPlan] = useState<SupplementalPlan>(state.supplementalPlans.data);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [allFieldsDirty, setAllFieldsDirty] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [error, setError] = useState(null);

    const onSubmitForm = async () => {
        setError(null);
        if (enableSubmit) return;

        setEnableSubmit(true);
        const addSupplementalPlanRequest = {
            subscriptionId: state.subscriptionId,
            supplementalPlanId: supplementalPlan.id,
            supplementalGroupCode: supplementalPlan.groupCode,
        };

        try {
            const addPlanResponse = await billingService.addSupplementalPlanToSubscription(addSupplementalPlanRequest);
            if (addPlanResponse.status !== 200) {
                setError(`Add supplemental plan failed with status = ${addPlanResponse.status}`);
                setEnableSubmit(true);
                return;
            }
            setEnableSubmit(false);
            onSubmit();
            closeModal();
        } catch (ex) {
            setError(`Add supplemental plan failed : ${ex}`);
            setEnableSubmit(false);
        }
    };

    const options = state.supplementalPlans.data.map((supplementalPlan) => {
        return {
            value: supplementalPlan.id,
            label: <div>{supplementalPlan.name}</div>,
        };
    });

    return (
        <div
            data-testid="main-div-testid"
            className={classes.container}
            onClick={(e) => {
                const target = e.target as HTMLElement;
                if (target.className === classes.container) closeModal();
            }}
        >
            <Modal open={true} onClose={closeModal}>
                <Box className={classes.box}>
                    <Form
                        onSubmit={onSubmitForm}
                        defaultValues={defaultValues}
                        allFieldsDirty={allFieldsDirty}
                        setAllFieldsDirty={setAllFieldsDirty}
                    >
                        <div className={classes.formGroup}>
                            <label htmlFor="plans">Select Add-on:</label>
                            <Select
                                id="select-supplemental-plan"
                                name={"select-supplemental-plan"}
                                aria-label="select-supplemental-plan"
                                options={options}
                                value={supplementalPlan ? options.find((option) => option.value === supplementalPlan.id) : null}
                                defaultValue={"Select"}
                                onChange={(e) => setSupplementalPlan(state.supplementalPlans.data.find((plan) => plan.id === e.value))}
                                required={true}
                            />
                        </div>
                        <Button text={"Submit"} enableSubmit={enableSubmit} className={classes.btn} />
                    </Form>
                    <div hidden={!error} className={classes.deleteBtn}>
                        <p>{error}</p>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default authGuard(AddonModal);
