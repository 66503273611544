import authGuard from "../../../shared/components/AuthGuard";
import React, {useEffect, useState} from "react";
import {Invoice} from "../../../models/Invoice";
import * as billingService from "../../../services/BillingService";
import {HTTP_STATUS} from "../../../models/HttpStatus";
import InvoiceTable from "../../form/components/invoice/InvoiceTable";
import classes from "../processPayment/ProcessPayment.module.scss"
import {InvoiceStatus} from "../../../models/InvoiceStatus";
import {Currency} from "../../../models/Currency";
import moment from "moment";
import {Message} from "../../../models/Message";
import {ErrorType} from "../../../models/ErrorType";
import {PaymentStatus} from "../../../models/PaymentStatus";
import {useCustomerContext} from "../landingPage/useLandingPage";
import ProcessPaymentModal from "../../form/components/modal/processPayment/ProcessPaymentModal";
import {convertNumberToCurrency} from "../../../utils/CommonUtils";
import {ProcessAllPaymentIntentRequest} from "../../../models/ProcessAllPaymentIntentRequest";
import {ProcessAllPaymentIntentResponse} from "../../../models/ProcessAllPaymentIntentResponse";

const ProcessPayment = ({disableVirtualization}) => {
    const state = useCustomerContext();
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [invoice, setInvoice] = useState<Invoice>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [message, setMessage] = useState<Message>(null);

    useEffect(() => {
        (async () => { await getAllInvoices();})();
    }, []);

    async function getAllInvoices() {
        const invoicesResponse = await billingService.getAllInvoicesByCustomerId(state.id);
        if (invoicesResponse.status !== HTTP_STATUS.OK) {
            setMessage({
                message: `Could not retrieve invoices for billing Customer Id = ${state.id}`,
                errorType: ErrorType.ERROR
            });
            return null;
        }
        setInvoices(invoicesResponse.data.filter(invoice => invoice.status === InvoiceStatus.OPEN));
    }

    const onInvoiceSelection = (invoice) => {
        setMessage(null);
        setInvoice(invoice);
        setModalOpen(invoice ? invoice.status === InvoiceStatus.OPEN : false);
    }

    const processPayment = async (paymentMethodId: string, paymentAmount: number = invoice.balance.amountDue, note: string, publishEvent?: boolean) => {
        let paymentIntentRequest = {
            customerId: invoice.customerId,
            invoiceId: invoice.id,
            paymentMethodId: paymentMethodId,
            amount: paymentAmount,
            currency: Currency.USD,
            description: note ? note : null,
            dueDate: moment(new Date()).format("YYYY-MM-DD"),
            confirm: true,
            metadata: {"publishEvent": publishEvent ? publishEvent.toString() : "true"}
        };
        try {
            const paymentIntentResponse = await billingService.processPayment(paymentIntentRequest);
            if (paymentIntentResponse.status !== HTTP_STATUS.OK) return null;
            const paymentIntent = paymentIntentResponse.data.data;
            return paymentIntent.status === PaymentStatus.SUCCESS ? paymentMethodId : null;
        } catch (ex) {
            return null;
        }
    };

    const processAllPayments = async (paymentAmount: number = invoice.balance.amountDue, note: string) => {
        let processAllRequest: ProcessAllPaymentIntentRequest = {
            customerId: invoice.customerId,
            invoiceId: invoice.id,
            amount: paymentAmount,
            currency: Currency.USD,
            useDefault: true,
            description: note ? note : null,
            metadata: {}
        };
        try {
            const processAllPaymentResponse = await billingService.processAllPayments(processAllRequest);
            if (processAllPaymentResponse.status !== HTTP_STATUS.OK) return null;
            const paymentIntent = processAllPaymentResponse.data.data;
            return paymentIntent.status === PaymentStatus.SUCCESS ? paymentIntent.paymentIntents[0].paymentMethodId : null;
        } catch (ex) {
            return null;
        }
    };

    const handleOnSubmit = async (paymentMethodId, paymentAmount, note, submitter) => {
        setMessage(null);

        if(paymentAmount > invoice.balance.amountDue || paymentAmount < 0) {
            const invalidAmountMsg = {
                message: `Enter a positive amount no greater than ${convertNumberToCurrency(invoice.balance.amountDue)}`,
                errorType:ErrorType.ERROR
            }
            setMessage(invalidAmountMsg)
        } else if (submitter === "process-selected") {
            const paymentResult = await processPayment(paymentMethodId, paymentAmount, note);
            const msg = paymentResult ?
                {
                    message: `Payment success with: ${paymentResult}`,
                    errorType:ErrorType.SUCCESS
                } :
                {
                    message: `Payment failed.`,
                    errorType:ErrorType.ERROR
                };
            setMessage(msg);
        }
        else if (submitter === "process-all") {
            const paymentResult = await processAllPayments(paymentAmount, note)
                if (paymentResult) {
                    setMessage({
                        message: `Payment success with: ${paymentResult}`,
                        errorType: ErrorType.SUCCESS
                    })
                    closeModal();
                    await getAllInvoices();
                    return;
                }
            setMessage({
                message: `Payment failed.`,
                errorType:ErrorType.ERROR
            })
        }
        else return;
        closeModal();
        await getAllInvoices();
    };

    function closeModal() {
        setModalOpen(false);
    }

    return (
        <div>
            <div className={classes.container}>
                {invoices &&
                    <InvoiceTable invoices={invoices} onInvoiceSelection={onInvoiceSelection} classes={classes} disableVirtualization={disableVirtualization}/>}
                {modalOpen && invoice && <ProcessPaymentModal invoice={invoice} closeModal={() => setModalOpen(false)} onSubmit={handleOnSubmit}/>}
                <div hidden={!message}
                     className={`${message?.errorType === ErrorType.ERROR ? classes.deleteBtn : classes.successBtn}`}>
                    <p>{message?.message}</p>
                </div>
            </div>
        </div>
    );
}
export default authGuard(ProcessPayment);
