import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../redux/hooks";
import {MODAL_TYPE, setModalType} from "../../../redux/modalSlice";
import Modal from "../../../shared/components/Modal";
import './ReservationModals.scss';
import {useForm} from "react-hook-form";
import {VehicleItem} from "../../../models/VehicleItem";
import {swapVehicle} from "../../../services/ReservationsService";
import {toaster} from "../../../shared/util/Toaster";


const SwapVehicleModal = () => {
    const reservationIdRegex = /^[0-9A-Z]{4}$|^[a-zA-Z0-9]{20}$/
    const vinRegex = /^[0-9A-Z]{17}$/;
    const licensePlateRegex = /^[A-Z0-9]*$/;
    const modelNameRegex = /^[A-Za-z0-9\s\-]*$/;
    const modelYearRegex = /^[0-9]{4}$/;
    const paintDescRegex = /^[\w\/\s-]*$/;

    const dispatch = useDispatch();
    const modalStore = useAppSelector((state) => state.modal);
    const {register, getValues, handleSubmit, formState: {errors}, reset} = useForm();

    const closeModal = useCallback(() => {
        dispatch(setModalType(MODAL_TYPE.CLOSED));
    }, [dispatch]);

    const cancelModal = useCallback(() => {
        closeModal();
        reset();
    }, []);

    const handleVehicleSwap = async () => {

        const vehicle: VehicleItem = new class implements VehicleItem {
            licensePlate: string;
            modelName: string;
            modelYear: string;
            paintDesc: string;
            vin: string;
        };
        vehicle.vin = getValues("vin");
        vehicle.licensePlate = getValues("licensePlate");
        vehicle.modelName = getValues("modelName");
        vehicle.paintDesc = getValues("paintDesc");
        vehicle.modelYear = getValues("modelYear");

        const reservationId: string = getValues("reservationId");

        await swapVehicle(reservationId, vehicle)
            .catch(() => {
                toaster.burnt(
                    {
                        code: `Swap Vehicle Error`,
                        message: 'Message: Error swapping vehicle.'
                    })
            });
        reset();
        closeModal();
    };

    return (
        <Modal isOpen={modalStore.modalType === MODAL_TYPE.SWAP_VEHICLE}>
            <form onSubmit={handleSubmit(handleVehicleSwap)}>
                <p>Vehicle Swap</p>
                <p style={{color: "red"}}>Only confirm once the VIN swap has taken place</p>
                <label style={{display: "table-cell"}} htmlFor="reservation_id_input">Reservation ID:</label>
                <input type="text" id="reservation_id_input"
                       {...register("reservationId", {
                           required: "Please enter a valid reservation Id.",
                           pattern: {
                               value: reservationIdRegex,
                               message: "Invalid reservation Id"
                           },
                           maxLength: {
                               value: 20,
                               message: "Must be 20 characters or less"
                           }
                       })}
                       data-testid="reservation-id-input"
                />
                <br/>
                {errors.reservationId && <span style={{color: "red"}}>{errors.reservationId.message}</span>}
                <br/>
                <label style={{display: "table-cell"}} htmlFor="vin_input">New VIN:</label>
                <input type="text" id="vin_input"
                       {...register("vin", {
                           required: "Please enter a valid VIN.",
                           pattern: {
                               value: vinRegex,
                               message: "Invalid VIN"
                           },
                           maxLength: {
                               value: 17,
                               message: "Must be 17 characters or less"
                           }
                       })}
                       data-testid="swap-vin-input"
                />
                <br/>
                {errors.vin && <span style={{color: "red"}}>{errors.vin.message}</span>}
                <br/>
                <label style={{display: "table-cell"}} htmlFor="licensePlate_input">License Plate: </label>
                <input type="text" id="licensePlate_input"
                       {...register("licensePlate", {
                           required: "Please enter a valid license plate",
                           pattern: {
                               value: licensePlateRegex,
                               message: "Invalid License Plate"
                           },
                           maxLength: {
                               value: 7,
                               message: "Must be 7 characters or less"
                           }
                       })}
                       data-testid="licensePlate-input"
                />
                <br/>
                {errors.licensePlate && <span style={{color: "red"}}>{errors.licensePlate.message}</span>}
                <br/>
                <label style={{display: "table-cell"}} htmlFor="modelName_input">Model Name: </label>
                <input type="text" id="modelName_input"
                       {...register("modelName", {
                           required: "Please enter a valid model name",
                           pattern: {
                               value: modelNameRegex,
                               message: "Invalid Model Name"
                           },
                           maxLength: {
                               value: 25,
                               message: "Must be 25 characters or less"
                           }
                       })}
                       data-testid="model-name-input"
                />
                <br/>
                {errors.modelName && <span style={{color: "red"}}>{errors.modelName.message}</span>}
                <br/>
                <label style={{display: "table-cell"}} htmlFor="modelYear_input">Model Year: </label>
                <input type="text" id="modelYear_input"
                       {...register("modelYear", {
                           required: "Please enter a valid model year",
                           pattern: {
                               value: modelYearRegex,
                               message: "Invalid Model Year"
                           },
                           maxLength: {
                               value: 4,
                               message: "Must be 4 characters or less"
                           }
                       })}
                       data-testid="model-year-input"
                />
                <br/>
                {errors.modelYear && <span style={{color: "red"}}>{errors.modelYear.message}</span>}
                <br/>
                <label style={{display: "table-cell"}} htmlFor="paintDesc_input">Paint Description: </label>
                <input type="text" id="paintDesc_input"
                       {...register("paintDesc", {
                           required: "Please enter a valid paint Description",
                           pattern: {
                               value: paintDescRegex,
                               message: "Invalid paint description"
                           },
                           maxLength: {
                               value: 40,
                               message: "Must be 40 characters or less"
                           }
                       })}
                       data-testid="paint-desc-input"
                />
                <br/>
                {errors.paintDesc && <span style={{color: "red"}}>{errors.paintDesc.message}</span>}
                <br/>
                <button type="submit" className="btn btn-primary" style={{marginTop: 20}}>
                    Confirm
                </button>
                <button type="button" className="btn btn-primary" style={{marginTop: 20, marginLeft: "1rem"}}
                        onClick={cancelModal} data-testid="cancel-vehicle-swap">
                    Cancel
                </button>
            </form>
        </Modal>
    );
}

export default SwapVehicleModal;
