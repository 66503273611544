import React, {useEffect} from 'react';
import {useIsAuthenticated} from "@azure/msal-react";
import {useNavigate} from "react-router-dom";

const AuthGuard = ({children}) => {
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();

    useEffect(() => {
        !isAuthenticated && navigate('/');
    }, [isAuthenticated, navigate]);

    return (
        <>
            {isAuthenticated ? children : null}
        </>
    );
};

const authGuard = (Component) => ({...props}) => {
    return (
        <AuthGuard>
            <Component {...props} />
        </AuthGuard>
    );
}

export default authGuard;
