import classes from "../CreditsNoteModal.module.scss";
import React from "react";
import {Box, Modal} from "@mui/material";

const ConfirmationModal = ({title, dialogTitle, displayData="", closeModal, confirmation}) => {
    return (
        <div data-testid="main-div-testid" className={classes.container} onClick={(e) => {
            const target = e.target as HTMLElement
            if (target.className === classes.container) closeModal()
        }}>
            <Modal open={true} onClose={closeModal}>
                <Box className={classes.box}>
                    <fieldset className={classes.fieldSet}>
                        <legend className={classes.legend}>{title}</legend>
                        <label className={classes.label}>{dialogTitle}</label>
                        <p className={classes.display_linebreak}>{displayData}</p>
                        <p>Are you sure you want to continue?</p>
                        <div>
                            <button className="btn btn-primary modal-button" onClick={confirmation}
                                    data-testid="modal-confirm-yes-button">
                                Yes
                            </button>
                            <button className="btn btn-primary modal-button" onClick={() => closeModal()}
                                    data-testid="modal-confirm-no-button">
                                No
                            </button>
                        </div>
                    </fieldset>
                </Box>
            </Modal>
        </div>
    );
};

export default ConfirmationModal;