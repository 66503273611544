import authGuard from "../../../shared/components/AuthGuard";
import React, {useEffect, useState} from "react";
import {Invoice} from "../../../models/Invoice";
import * as billingService from "../../../services/BillingService";
import {HTTP_STATUS} from "../../../models/HttpStatus";
import classes from "../invoice/Invoice.module.scss";
import InvoiceTable from "../../form/components/invoice/InvoiceTable";
import OrphanInvoiceModal from "../../form/components/modal/orphanInvoice/OrphanInvoiceModal";
import AddInvoiceLineItemView from "./AddInvoiceLineItemView";
import {InvoiceStatus} from "../../../models/InvoiceStatus";
import {useCustomerContext} from "../landingPage/useLandingPage";

const InvoicePage = ({disableVirtualization}) => {
    const state = useCustomerContext();
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [error, setError] = useState(null);
    const [openInvoiceItemModal, setOpenInvoiceItemModal] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [invoice, setInvoice] = useState<Invoice>(null);

    useEffect(() => {
        (async () => { await getAllInvoices();})();
    }, []);

    async function getAllInvoices() {
        setError(null);
        const invoicesResponse = await billingService.getAllInvoicesByCustomerId(state.id);
        if (invoicesResponse.status !== HTTP_STATUS.OK) {
            setError(`Could not retrieve invoices for billing Customer Id = ${state.id}`);
            return null;
        }

        if (invoice && invoicesResponse.data && invoicesResponse.data.length !== 0) {
            const updatedInvoice = invoicesResponse.data.find(item => item.id === invoice.id)
            setInvoice(updatedInvoice)
            setOpenInvoiceItemModal(updatedInvoice.status === InvoiceStatus.DRAFT);
        }

        setInvoices(invoicesResponse.data.filter(invoice => invoice.status === InvoiceStatus.DRAFT));
    }

    async function handleOnSubmit() {
        await getAllInvoices();
    }

    const onInvoiceSelection = (invoice) => {
        setInvoice(invoice);
        setOpenInvoiceItemModal(invoice ? invoice.status === InvoiceStatus.DRAFT : false);
    }

    return (
        <div>
            <div className={classes.container}>
                <div className={classes.divAdd}>
                    <button data-testid="add-orphan-invoice-test-id" onClick={() => setModalOpen(true)}
                            className={`${classes.button} ${classes.enable_bg_color}`}>Create New Invoice
                    </button>
                </div>

                {modalOpen && <OrphanInvoiceModal billingCustomerId={state.id}
                                                  taxRates={state.subscription?.defaultTaxRateIds?.join(",") || []}
                                                  closeModal={() => setModalOpen(false)} onSubmit={handleOnSubmit}
                                                  setError={setError}/>}
                {invoices &&
                    <InvoiceTable invoices={invoices} onInvoiceSelection={onInvoiceSelection} classes={classes} disableVirtualization={disableVirtualization}/>}
                {invoice && <AddInvoiceLineItemView invoice={invoice} taxRates={state.subscription?.defaultTaxRates || []} isEditable={openInvoiceItemModal}
                                                    handleOnSubmit={handleOnSubmit} disableVirtualization={disableVirtualization}/> }
                <div hidden={!error} className={classes.deleteBtn}>
                    <p>{error}</p>
                </div>
            </div>
        </div>
    );
}
export default authGuard(InvoicePage);
