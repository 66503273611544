import classes from "./OpsHeader.module.scss";
import {useNavigate} from "react-router-dom";

const OpsHeader = () => {
    const navigate = useNavigate();
    return (
        <div className={classes.header} data-testid="ops-header-id" role="banner">
            <div>
                <img src={'/assets/FordDriveLogo.svg'} onClick={() => navigate("/admin")} className={classes.image} alt="Ford Drive Logo"/>
            </div>
        </div>
    );
}

export default OpsHeader;