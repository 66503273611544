import classes from "./Common.module.scss";
import React, {useState} from "react";
import {useCollapse} from "react-collapsed";
const SubscriptionFields = ({subscription}) => {
    const [isExpanded, setExpanded] = useState(true);
    const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

    function handleOnClick() {
        setExpanded(!isExpanded);
    }

    return (
        <div>
            <fieldset className={classes.fieldSet}>
                <legend className={classes.legend}>Subscription</legend>
                <div className={classes.expand} {...getToggleProps({onClick: handleOnClick})}>
                    {isExpanded ? '-' : '+'}
                </div>
                <div {...getCollapseProps()}>
                    <div className={classes.div}>
                        <label className={classes.label}>Subscription ID:</label>
                        <label className={classes.labelText}>{subscription.id}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Status:</label>
                        <label className={classes.labelText}>{subscription.status}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Effective Date:</label>
                        <label className={classes.labelText}>{subscription.effectiveDate}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Billing Cycle Anchor:</label>
                        <label className={classes.labelText}>{subscription.billingCycleAnchor}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Current Billing Date:</label>
                        <label className={classes.labelText}>{subscription.currentBillingDate}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Next Billing Date:</label>
                        <label className={classes.labelText}>{subscription.nextBillingDate}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Current Market Tax Rate:</label>
                        <label
                            className={classes.labelText}>{subscription?.defaultTaxRateIds?.map(item => item).join(', ')}</label>
                    </div>
                </div>
            </fieldset>
        </div>
    );
}
export default SubscriptionFields;