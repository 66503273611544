import React, {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import CurrencyInput from 'react-currency-input-field';
import classes from './FormComponents.module.scss';
export const Form: React.FC<any> = ({
                                        defaultValues,
                                        mode,
                                        children,
                                        onSubmit,
                                        allFieldsDirty = null,
                                        setAllFieldsDirty = null,
                                        startingValues = null,
                                    }) => {
    const methods = useForm({ mode, defaultValues });

    useEffect(() => {
        if (!setAllFieldsDirty) return;
        const allDirty = Object.keys(methods.formState.dirtyFields).length === Object.keys(defaultValues).length;
        if (allFieldsDirty === allDirty) return;
        setAllFieldsDirty(allDirty);
    }, [methods.formState]);

    useEffect(() => {
        if (!startingValues) return;
        Object.keys(methods.getValues()).forEach((key) => {
            methods.setValue(key, startingValues[key], { shouldDirty: true });
        });
    }, [startingValues]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit) }
            >{children}</form>
        </FormProvider>
    );
};

export const Input: React.FC<any> = ({
                                         register,
                                         name,
                                         title,
                                         className,
                                         labelClassName,
                                         containerClassName,
                                         rules,
                                         errors,
                                         hideErrors,
                                         lightColor = false,
                                         readOnly,
                                         inputType = 'text',
                                         maxValue,
                                         defaultValue,
                                         rulesMandatory = true,
                                         ...rest
                                     }: any) => {
    return (
        <div className={`${containerClassName} ${classes.container}`}>
            <div className={classes.containerInput}>
                <label className={`${labelClassName} ${classes.label}`} htmlFor={name}>
                    {title}
                </label>
                <input
                    className={`${className} ${classes.input} ${errors && errors[name] ? classes.input_error : ''}`}
                    type= {inputType}
                    max={maxValue}
                    defaultValue={defaultValue}
                    id={name}
                    readOnly={readOnly}
                    data-testid={name}
                    {...register(name, rulesMandatory && {...rules })}
                    {...rest}
                />
            </div>
            {!hideErrors && errors && errors[name] && (
                <ErrorMessage role="error" errorMessage={errors[name].message} lightColor={lightColor} />
            )}
        </div>
    );
};
export const InputWithHandler: React.FC<any> = ({
                                         register,
                                         name,
                                         title,
                                         className,
                                         labelClassName,
                                         containerClassName,
                                         rules,
                                         errors,
                                         hideErrors,
                                         lightColor = false,
                                         readOnly,
                                         inputType = 'text',
                                         maxValue,
                                         defaultValue,
                                         onChangeHandler,
                                         value,
                                         rulesMandatory = true,
                                         ...rest
                                     }: any) => {
    return (
        <div className={`${containerClassName} ${classes.container}`}>
            <div className={classes.containerInput}>
                <label className={`${labelClassName} ${classes.label}`} htmlFor={name}>
                    {title}
                </label>
                <input
                    className={`${className} ${classes.input} ${errors && errors[name] ? classes.input_error : ''}`}
                    type= {inputType}
                    max={maxValue}
                    defaultValue={defaultValue}
                    value={value}
                    id={name}
                    readOnly={readOnly}
                    data-testid={name}
                    {...register(name, rulesMandatory && {...rules })}
                    {...rest}
                    onChange={onChangeHandler}
                />
            </div>
            {!hideErrors && errors && errors[name] && (
                <ErrorMessage role="error" errorMessage={errors[name].message} lightColor={lightColor} />
            )}
        </div>
    );
};
export const Button: React.FC<any> = ({ text, className, ...rest }) => {
    return (
        <div className={`${classes.container}`}>
            <button className={`${className} ${classes.button} button--primary--next`} data-testid={text} {...rest}>
                {text}
            </button>
        </div>
    );
};
export const ErrorMessage: React.FC<any> = ({ errorMessage, className, lightColor = false, ...rest }) => {
    const imagePath = lightColor ? '/assets/ValidationError.svg' : '/assets/icon--warning.svg';
    return (
        <div className={`${className} ${classes.validation_error}`} {...rest}>
            <img src={process.env.PUBLIC_URL + imagePath} alt="Validation Error" className={classes.error_image}></img>
            <p className={`${classes.error_message} ${lightColor ? classes.light : ''}`}>{errorMessage}</p>
        </div>
    );
};
export const Select: React.FC<any> = ({register,
                                         name,
                                         title,
                                         className,
                                         labelClassName,
                                         containerClassName,
                                          rules,
                                          errors,
                                          hideSelection,
                                          onSelectionChangedHandler,
                                         selectionItems,
                                         lightColor = false,
                                         ...rest
                                     }: any) => {
    const [selection, setSelection] = React.useState('');
    const onSelectionChanged = (target) => {
        setSelection(target.value);
        onSelectionChangedHandler(target.value);
    };

    return (
        <div className={`${containerClassName} ${classes.container}`}>
            <div className={classes.containerInput}>
                <fieldset className={classes.fieldSet}>
                    <legend className={classes.legend}>{title}</legend>
                    <label className={classes.selectLabel}>Choose Action:</label>
                    <select
                        className={`${className} ${classes.select} ${errors && errors[name] ? classes.input_error : ''}`}
                        id={name}
                        value={selection}
                        onChange={(event) => onSelectionChanged(event.target)}
                        data-testid={name}
                        {...rest}
                    >
                        <option value={''}>{`—Select—`}</option>
                        <optgroup label="Actions">
                            {selectionItems.map(({key, value}) => {
                                return (
                                    <option key={key} value={value}>{value}</option>
                                );
                            })}
                        </optgroup>
                    </select>
                </fieldset>
            </div>
            <p className={classes.selectedOption} hidden={hideSelection}>
                {`Selected ${title} ${selection}`}
            </p>
        </div>
    );
};

export const InputCurrency: React.FC<any> = ({
                                                 register,
                                                 name,
                                                 title,
                                                 className,
                                                 labelClassName,
                                                 containerClassName,
                                                 rules,
                                                 errors,
                                                 hideErrors,
                                                 lightColor = false,
                                                 readOnly,
                                                 inputType = 'text',
                                                 rulesMandatory = true,
                                                 defaultValue,
                                                 decimalsLimit = 2,
                                                 prefix = "$",
                                                 allowNegativeValue = true,
                                                 ...rest
                                             }: any) => {

    return (
        <div className={`${containerClassName} ${classes.container}`}>
            <div className={classes.containerInput}>
                <label className={`${labelClassName} ${classes.label}`} htmlFor={name}>
                    {title}
                </label>
                <CurrencyInput
                    className={`${className} ${classes.input} ${errors && errors[name] ? classes.input_error : ''}`}
                    type= {inputType}
                    id={name}
                    readOnly={readOnly}
                    data-testid={name}
                    defaultValue={defaultValue}
                    decimalsLimit={decimalsLimit}
                    prefix = {prefix}
                    allowNegativeValue = {allowNegativeValue}
                    {...register(name, rulesMandatory && {...rules })}
                    {...rest}
                />
            </div>
            {!hideErrors && errors && errors[name] && (
                <ErrorMessage role="error" errorMessage={errors[name].message} lightColor={lightColor} />
            )}
        </div>
    );
};

