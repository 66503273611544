import authGuard from "../../../shared/components/AuthGuard";
import { BsFillTrashFill } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import * as billingService from "../../../services/BillingService";
import { HTTP_STATUS } from "../../../models/HttpStatus";
import { PendingInvoiceItem } from "../../../models/PendingInvoice";
import classes from "./PendingInvoiceItemPage.module.scss";
import PendingInvoiceItemModal from "../../form/components/modal/pendingInvoice/PendingInvoiceItemModal";
import { currencyValueFormatter } from "../../../utils/CommonUtils";
import { useCustomerContext } from "../landingPage/useLandingPage";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";

const PendingInvoiceItemPage = ({ disableVirtualization }) => {
    const state = useCustomerContext();
    const [pendingInvoiceItems, setPendingInvoiceItems] = useState<PendingInvoiceItem[]>([]);
    const [error, setError] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        (async () => {
            await getAllPendingInvoiceItems();
        })();
    }, []);

    async function handleOnSubmit() {
        await getAllPendingInvoiceItems();
    }

    async function getAllPendingInvoiceItems() {
        setError(null);
        const pendingInvoiceItemsResponse = await billingService.getAllPendingInvoiceItemsByInvoiceId(state.id);
        if (pendingInvoiceItemsResponse.status !== HTTP_STATUS.OK) {
            setError(`Could not retrieve pending invoice billing Customer Id = ${state.id}`);
            return null;
        }
        setPendingInvoiceItems(pendingInvoiceItemsResponse.data);
    }

    async function onDeletePendingInvoiceItem(row) {
        const deletePendingInvoice = await billingService.deletePendingInvoiceItem(row.id);
        if (deletePendingInvoice.status !== HTTP_STATUS.NO_CONTENT) {
            setError(`Could not delete pending invoice Id = ${row.id} for billing Customer Id=${state.id}`);
        }
        await getAllPendingInvoiceItems();
    }

    const columns = [
        { field: "pendingInvoiceItemId", headerName: "Pending Invoice Item ID", width: 300 },
        { field: "description", headerName: "Description", width: 500 },
        { field: "unitCost", headerName: "Item Cost", width: 150, valueFormatter: currencyValueFormatter },
        { field: "unitQuantity", headerName: "Item Quantity", width: 200 },
        { field: "taxRate", headerName: "Tax Rate", width: 300 },
        { field: "effectiveDate", headerName: "Effective Date", width: 200 },
        {
            field: "actions",
            headerName: "Actions",
            width: 150,
            renderCell: (params: GridRenderCellParams<any, Date>) => params.value,
            sortable: false,
        },
    ];

    const getPendingInvoiceItemsDisplayData = (pendingInvoiceItems: PendingInvoiceItem[]) => {
        return pendingInvoiceItems.map((pendingInvoiceItem, index) => {
            return {
                id: pendingInvoiceItem.id,
                pendingInvoiceItemId: pendingInvoiceItem.id,
                description: pendingInvoiceItem.description,
                unitCost: pendingInvoiceItem.unitCost,
                unitQuantity: pendingInvoiceItem.unitQuantity,
                taxRate: pendingInvoiceItem.taxRates,
                effectiveDate: pendingInvoiceItem.effectiveDate,
                actions: (
                    <BsFillTrashFill
                        className={classes.deleteBtn}
                        data-testid={`delete-${index}`}
                        onClick={() => onDeletePendingInvoiceItem(pendingInvoiceItem)}
                    />
                ),
            };
        });
    };

    return (
        <div>
            <div className={classes.container}>
                <div className={classes.divAdd}>
                    <button
                        data-testid="add-pending-invoice-test-id"
                        onClick={() => setModalOpen(!modalOpen)}
                        className={`${classes.button} ${classes.enable_bg_color}`}
                    >
                        Add Pending Item
                    </button>
                </div>

                {pendingInvoiceItems && (
                    <div className={classes.table}>
                        <DataGrid
                            rows={getPendingInvoiceItemsDisplayData(pendingInvoiceItems)}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                            }}
                            pageSizeOptions={[10, 20, 50]}
                            disableVirtualization={disableVirtualization}
                            disableColumnSelector
                        />
                    </div>
                )}
                {modalOpen && (
                    <PendingInvoiceItemModal
                        data-testid="add-pendinginvoice-modal"
                        taxRates={state.subscription?.defaultTaxRateIds?.join(",") || []}
                        pendingInvoiceItem={{ billingCustomerId: state.id }}
                        closeModal={() => setModalOpen(false)}
                        onSubmit={handleOnSubmit}
                    />
                )}
                <div hidden={!error} className={classes.deleteBtn}>
                    <p>{error}</p>
                </div>
            </div>
        </div>
    );
};
export default authGuard(PendingInvoiceItemPage);
