import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export enum MODAL_TYPE {
    SWAP_VEHICLE="SWAP_VEHICLE",
    CONFIRMATION_COMMAND="CONFIRMATION_COMMAND",
    CREDIT_REPORT="CREDIT_REPORT",
    CLOSED="CLOSED",
    NOTES="NOTES"
}

export interface ModalState {
    modalType: MODAL_TYPE,
}

const initialState: ModalState = {
    modalType: null,
};

// TODO : split reservation id and vehicles into their own slice
export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setModalType: (state, action: PayloadAction<MODAL_TYPE>) => {
            state.modalType = action.payload;
        },
    },
});

export const { setModalType } = modalSlice.actions;

export default modalSlice.reducer;
