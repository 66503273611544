import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Input } from '../FormComponents';
import classes from './LandingPageFields/LandingPageFormFields.module.scss'
import {InputModel} from "../models";

const Child: React.FC<any> = memo(({ register, formState: { errors }, disabled }) => (
    <>
        <Input
            register={register}
            name="customerId"
            title="Billing Customer Id:"
            placeholder="Billing Customer Id"
            maxLength={128}
            data-testid="billingCustomerId-testid"
            labelClassName={classes.input_label}
            errors={errors}
            lightColor={true}
            disabled={disabled}
            readOnly={false}
            rules={{
                required: {
                    value: true,
                    message: 'Billing Customer Id is required',
                }
            }}
        />
    </>
));

const BillingCustomerIdField: React.FC<InputModel> = ({ disabled = false }) => {
    const methods = useFormContext();
    return <Child {...methods} disabled={disabled} />;
};
export default BillingCustomerIdField;