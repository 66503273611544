import React, {useCallback, useEffect, useState} from "react";

import './ReservationModals.scss';
import {MODAL_TYPE, setModalType} from "../../../redux/modalSlice";
import Modal from "../../../shared/components/Modal";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../redux/hooks";
import {AxiosResponse} from "axios";
import {CreditReportInfo} from "../../../models/CreditReportInfo";
import {getCreditReport} from "../../../services/OperationService";

const CreditReportModal = () => {

    const dispatch = useDispatch();
    const modalStore = useAppSelector((state) => state.modal);

    const [text, setText] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    
    const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const textLines = event.target.value.split('\n');
        if (textLines.length <= 50) {
            setText(event.target.value);
        }
    };

    const closeModal = useCallback(() => {
        dispatch(setModalType(MODAL_TYPE.CLOSED));
    }, [dispatch]);

    const reset = useCallback(() => {
        setText('');
    }, []);

    const cancelModal = useCallback(() => {
        closeModal();
        reset();
        setError(false)
        setErrorMessage(null)
    }, []);

    const checkData = () =>{
        const textLines = text.split('\n');
        let idString = text.replace(/\s+/g, '').trim();
        idString = idString.replace(/,\s*$/, "");
        for (let resId of textLines) {
            if(!resId.match('^[a-zA-Z0-9]{4}$|^[a-zA-Z0-9]{20}$')){
                setError(true)
                setErrorMessage('Invalid Reservation or Simple Id')
                return false; 
            }
        }
        setError(false);
        setErrorMessage(null);
        return true;
    }

    const creditReportExportToCsv = async () => {
        if(!checkData()){
            return;
        }
        
        const response: AxiosResponse<[CreditReportInfo]> = await getCreditReport(text);
            
        const exportCreditReport: [CreditReportInfo] = response.data;
        const csv = [["Reservation ID", "Simple ID", "Status", "Waitlisted Date", "Market","User Id", "First Name", "Last Name", "Email", "Phone",  "Score"]
        ];
        exportCreditReport.map(report => [
            report.reservationId, report.simpleId, report.status, report.waitListedDate, report.market, report.userId, report.firstName, report.lastName, report.email[0], report.phone, report.score.toString()
        ]).forEach(r=>{
            csv.push(r)
        })
        const mappedCsv = csv.map(e => e.join(","))
        const joinedCsv = mappedCsv.join("\n")
        const csvFile = new Blob([joinedCsv], {type: 'text/plain'});
        const element = document.createElement("a");
        element.href = URL.createObjectURL(csvFile);
        element.download = "waitlisted-credit-report.csv";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        closeModal();
    };

    return (
        <Modal isOpen={modalStore.modalType === MODAL_TYPE.CREDIT_REPORT}>
        <div>
            <div>
                {error ? <p style={{color: 'red'}}>{errorMessage}</p> : null}
            </div>
            <textarea value={text} onChange={(handleTextareaChange)}
            placeholder="Enter the Reservation/Simple Id. Maximum items should be 50"
            />
            <br/>
            <button id='confirm' type="button" className="btn btn-primary" style={{marginTop: 20}}
                    onClick={creditReportExportToCsv} data-testid="confirm-credit-report"
                    disabled = {text.length<=0}
                    >
                Confirm
            </button>
            <button type = "button" className="btn btn-primary" style={{marginTop: 20, marginLeft: "1rem"}}
                    onClick={cancelModal} data-testid="cancel-credit-report">
                Cancel
            </button>
        </div>
        </Modal>
    );
}

export default CreditReportModal;
