import classes from "./Common.module.scss";
import React, {useState} from "react";
import {useCollapse} from "react-collapsed";
import {convertStringToCurrency} from '../../../../utils/CommonUtils'
const PlanFields = ({plan}) => {
    const [isExpanded, setExpanded] = useState(true);
    const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

    function handleOnClick() {
        setExpanded(!isExpanded);
    }

    return (
        <div>
            <fieldset className={classes.fieldSet}>
                <legend className={classes.legend}>Plan Info</legend>
                <div className={classes.expand} {...getToggleProps({onClick: handleOnClick})}>
                    {isExpanded ? '-' : '+'}
                </div>
                <div {...getCollapseProps()}>
                    <div className={classes.div}>
                        <label className={classes.label}>Plan ID:</label>
                        <label className={classes.labelText}>{plan.id}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Name:</label>
                        <label className={classes.labelText}>{plan.name}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Status:</label>
                        <label className={classes.labelText}>{plan.active.toString()}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Flat Rate:</label>
                        <label className={classes.labelText}>{convertStringToCurrency((plan.pricingDetails.flatRate/100).toString())}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Minimum Term:</label>
                        <label className={classes.labelText}>{plan.minimumTerm}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Billing Interval:</label>
                        <label className={classes.labelText}>{plan.billingInterval}</label>
                    </div>
                </div>
            </fieldset>
        </div>
    );
}
export default PlanFields;
