import {FcCheckmark, FcPlus} from "react-icons/fc";
import {BsFillPencilFill} from "react-icons/bs";
import authGuard from "../../../../shared/components/AuthGuard";
import React from "react";
import {currencyValueFormatter} from "../../../../utils/CommonUtils";
import {Invoice} from "../../../../models/Invoice";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams
} from "@mui/x-data-grid";

const InvoiceTable = ({invoices, onInvoiceSelection, classes, disableVirtualization}) => {
    const onRowSelection = (invoice) => {
        onInvoiceSelection(invoice)
    }

    const columns: GridColDef[] = [
        {
            field: "actions", headerName: "Actions", width: 150,
            renderCell: (params: GridRenderCellParams<any, Date>) => (params.value), sortable: false
        },
        {field: "stripeInvoiceNumber", headerName: "Stripe Invoice Number", width: 250},
        {field: "status", headerName: "Status", width: 150},
        {field: "description", headerName: "Description", width: 500},
        {field: "startDate", headerName: "Start Date", width: 150},
        {field: "endDate", headerName: "End Date", width: 150},
        {field: "dueDate", headerName: "Due Date", width: 150},
        {field: "amountDue", headerName: "Amount Due", width: 150, valueFormatter: currencyValueFormatter},
        {field: "amountPaid", headerName: "Amount Paid", width: 150, valueFormatter: currencyValueFormatter},
        {field: "creditNotes", headerName: "Credit Notes", width: 150, valueFormatter: currencyValueFormatter},
        {field: "amountRemaining", headerName: "Amount Remaining", width: 200, valueFormatter: currencyValueFormatter},
        {field: "id", headerName: "Billing Invoice ID", width: 300},
        {field: "externalBillingId", headerName: "Stripe Invoice ID", width: 300}
    ];

    const actionMap = {
        "OPEN": <FcPlus/>,
        "DRAFT": <BsFillPencilFill/>,
        "PAID": <FcCheckmark/>
    }

    const getInvoiceDisplayData = (invoices: Invoice[]) => {
        return invoices.map(invoice => {
            return {
                actions: actionMap[invoice.status],
                stripeInvoiceNumber: invoice.metadata["stripeInvoiceNumber"],
                status: invoice.status,
                description: invoice.description,
                startDate: invoice.period.start,
                endDate: invoice.period.end,
                dueDate: invoice.dueDate,
                amountDue: invoice.balance.amountDue,
                amountPaid: invoice.balance.amountPaid,
                creditNotes: invoice.credits.reduce((n, {amount}) => n + amount, 0),
                amountRemaining: invoice.balance.amountRemaining,
                id: invoice.id,
                externalBillingId: invoice.externalBillingId
            };
        });
    }

    return (
        <>
            {invoices && invoices.length !== 0 &&
                <div className={classes.table}>
                    <DataGrid
                        rows={getInvoiceDisplayData(invoices)}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 10},
                            },
                        }}
                        pageSizeOptions={[10, 20, 50]}
                        onRowSelectionModelChange={ids => {
                            const invoice = invoices.find(invoice => invoice.id === ids[0]);
                            onRowSelection(invoice);
                        }}
                        disableVirtualization={disableVirtualization}
                        disableColumnSelector
                    />
                </div>
            }
        </>
    );
}

export default authGuard(InvoiceTable);
