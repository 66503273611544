import React from "react";
import authGuard from "../../../shared/components/AuthGuard";
import useLandingPage from "./useLandingPage";
import classes from "../BillingService.module.scss";
import EmailFormField from "../../form/components/LandingPageFields/EmailFormField";
import { Button, Form } from "../../form/FormComponents";
import SelectBillingActionField from "../../form/components/LandingPageFields/SelectBillingActionField";
import SubscriptionFields from "../../form/components/common/SubscriptionFields";
import CustomerFields from "../../form/components/common/CustomerFields";
import PlanFields from "../../form/components/common/PlanFields";
import { Route, Routes } from "react-router-dom";
import InvoicePage from "../invoice/InvoicePage";
import PendingInvoiceItemPage from "../pendingInvoiceItem/PendingInvoiceItemPage";
import CreditsNote from "../creditsNote/CreditsNote";
import IssueRefund from "../issueRefund/IssueRefund";
import ProcessPayment from "../processPayment/ProcessPayment";
import AddonPage from "../addon/AddonPage";
import OpsHeader from "../../header/OpsHeader";
import VoidInvoicePage from "../voidInvoicesPage/VoidInvoicesPage";

const LandingPage = () => {
    const {
        defaultValues,
        onSubmit,
        enableSubmit,
        processing,
        allFieldsDirty,
        setAllFieldsDirty,
        onBillingActionEventHandler,
        error,
        customer,
        customerName,
        CustomerContextProvider,
    } = useLandingPage();

    return (
        <div className={classes.root}>
            <OpsHeader />
            <div className={classes.wrapper}>
                <Form
                    onSubmit={onSubmit}
                    defaultValues={defaultValues}
                    allFieldsDirty={allFieldsDirty}
                    setAllFieldsDirty={setAllFieldsDirty}
                >
                    <EmailFormField disabled={processing} />
                    <Button text={"Get Customer"} enableSubmit={enableSubmit} className={classes.button} />
                    <div hidden={!enableSubmit}>
                        <div className={classes.divColumn}>
                            {customer && defaultValues && (
                                <CustomerFields customer={customer} customerName={customerName} emailId={defaultValues.email} />
                            )}
                            {customer?.subscription && <SubscriptionFields subscription={customer.subscription} />}
                            {customer?.subscription?.plan && <PlanFields plan={customer.subscription.plan} />}
                        </div>
                        <div>
                            <SelectBillingActionField selectionEvent={onBillingActionEventHandler} />
                        </div>
                    </div>
                </Form>
                <div hidden={!enableSubmit}>
                    <div>
                        <CustomerContextProvider>
                            <Routes>
                                <Route path="invoice" element={<InvoicePage disableVirtualization={false} />} />
                                <Route path="pending-invoice" element={<PendingInvoiceItemPage disableVirtualization={false} />} />
                                <Route path="credits-note" element={<CreditsNote disableVirtualization={false} />} />
                                <Route path="issue-refund" element={<IssueRefund disableVirtualization={false} />} />
                                <Route path="process-payment" element={<ProcessPayment disableVirtualization={false} />} />
                                <Route path="addon" element={<AddonPage disableVirtualization={false} />} />
                                <Route path="void-invoice" element={<VoidInvoicePage disableVirtualization={false} />} />
                            </Routes>
                        </CustomerContextProvider>
                    </div>
                </div>
                <div hidden={!error} className={classes.error}>
                    <p>{error}</p>
                </div>
            </div>
        </div>
    );
};
export default authGuard(LandingPage);
