import React from 'react'
import authGuard from "../../shared/components/AuthGuard";
import ToastNotification from "../../shared/components/ToastNotification";
import Reservations from "../reservations/Reservations";
import OpsHeader from "../header/OpsHeader";
import classes from "./Admin.module.scss";



function Admin() {

    return(
    <div>
        <OpsHeader/>
        <div className={classes.admin}>
            <Reservations/>
            <ToastNotification/>
        </div>
    </div>)
}

export default authGuard(Admin);
