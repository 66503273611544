import authGuard from "../../../../shared/components/AuthGuard";
import React, {useState} from "react";
import {convertStringToCurrency, currencyValueFormatter, taxPercentageFormatter} from "../../../../utils/CommonUtils";
import {BsFillPencilFill, BsFillTrashFill} from "react-icons/bs";
import InvoiceItemModal from "../modal/invoiceItem/InvoiceItemModal";
import {InvoiceItem} from "../../../../models/InvoiceItem";
import {InvoiceStatus} from "../../../../models/InvoiceStatus";
import * as billingService from "../../../../services/BillingService";
import DialogModal from "../modal/confirmation/DialogModal";
import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";

const InvoiceLineItemTable = ({invoice, taxRates, classes, onSubmit, disableVirtualization}) => {
    const [openInvoiceItemModal, setOpenInvoiceItemModal] = useState(false);
    const [invoiceItem, setInvoiceItem] = useState<InvoiceItem>(null);
    const [deleteInvoiceItemModal, setDeleteInvoiceItemModal] = useState<boolean>(false); //-1 is close, others are invoice item index

    const onEditInvoiceItem = (item: InvoiceItem) => {
        setOpenInvoiceItemModal(true)
        setInvoiceItem(item);
    };

    const onDeleteInvoiceItem = (item: InvoiceItem) => {
        setDeleteInvoiceItemModal(true)
        setInvoiceItem(item);
    };

    const confirmationAction = async (item: InvoiceItem) => {
        deleteInvoiceItem(item.id).then((data) => {
            if (data.status !== 200) {
                return;
            }
            onSubmit();
            closeDeleteConfirmation();
        }).catch((ex) => {
            closeDeleteConfirmation();
        });
    };

    const deleteInvoiceItem = async (invoiceId: string) => {
        return await billingService.deleteInvoiceItem(invoiceId);
    };

    const closeDeleteConfirmation = () => {
        setDeleteInvoiceItemModal(false);
    };

    const columns: GridColDef[] = [
        {field: "stripeLineItemId", headerName: "Stripe Line Item ID", width: 300},
        {field: "description", headerName: "Description", width: 500},
        {field: "quantity", headerName: "Quantity", width: 150},
        {field: "amount", headerName: "Amount", width: 150, valueFormatter: currencyValueFormatter},
        {field: "subtotal", headerName: "Subtotal", width: 150, valueFormatter: currencyValueFormatter},
        {field: "taxRate", headerName: "Tax Rate", width: 150},
        {field: "taxAmount", headerName: "Tax Amount", width: 150, valueFormatter: currencyValueFormatter},
        {field: "total", headerName: "Total", width: 150, valueFormatter: currencyValueFormatter},
        {field: "startDate", headerName: "Start Date", width: 150},
        {field: "endDate", headerName: "End Date", width: 150},
        {field: "edit", headerName: "Edit", width: 150},
        {
            field: "delete", headerName: "Delete", width: 150,
            renderCell: (params: GridRenderCellParams<any, Date>) => (params.value), sortable: false
        }
    ];

    const getInvoiceItemDisplayData = (invoiceItems: InvoiceItem[]) => {
        return invoiceItems.map((invoiceItem, index) => {
            return {
                id: invoiceItem.id,
                stripeLineItemId: invoiceItem.externalBillingId,
                description: invoiceItem.description,
                quantity: invoiceItem.unitQuantity,
                amount: invoiceItem.unitCost,
                subtotal: invoiceItem.unitQuantity * invoiceItem.unitCost,
                taxRate: invoiceItem.taxRates.length === 0 ? null : taxPercentageFormatter(taxRates[0].percentage),
                taxAmount: invoiceItem.taxRates.length === 0 ? null : ((invoiceItem.unitQuantity * invoiceItem.unitCost) * Number(taxRates[0].percentage) / 100).toFixed(2),
                total: ((invoiceItem.unitQuantity * invoiceItem.unitCost) * (1 + (invoiceItem.taxRates.length === 0 ? 0 : Number(taxRates[0].percentage) / 100))).toFixed(2),
                startDate: invoiceItem.period === null ? null : invoiceItem.period.start,
                endDate: invoiceItem.period === null ? null : invoiceItem.period.end,
                edit: <BsFillPencilFill
                    onClick={invoice.status === InvoiceStatus.DRAFT ? () => onEditInvoiceItem(invoiceItem) : undefined}/>,
                delete: <BsFillTrashFill className={classes.deleteBtn}
                                         data-testid={`delete-invoice-item-${index}`}
                                         onClick={invoice.status === InvoiceStatus.DRAFT ? () => onDeleteInvoiceItem(invoiceItem) : undefined}/>
            };
        });
    };

    return (
        <>
            {invoice && invoice.status === InvoiceStatus.DRAFT && openInvoiceItemModal &&
                <InvoiceItemModal invoice={invoice} invoiceItem={invoiceItem} taxRates={taxRates} edit={true}
                                  closeModal={() => setOpenInvoiceItemModal(false)}
                                  onSubmit={onSubmit}/>}
            {invoice && invoice.items.length !== 0 &&
                <div className={classes.table}>
                    <DataGrid
                        columnVisibilityModel={{
                            edit: false,
                            delete: invoice.status === InvoiceStatus.DRAFT
                        }}
                        rows={getInvoiceItemDisplayData(invoice.items)}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 10},
                            },
                        }}
                        pageSizeOptions={[10, 20, 50]}
                        disableVirtualization={disableVirtualization}
                        disableColumnSelector
                    />
                </div>
            }
            {invoice && invoice.status === InvoiceStatus.DRAFT && deleteInvoiceItemModal &&
                <DialogModal title="Delete" dialogTitle={`Deleting Invoice Item - ${invoiceItem.id}`}
                             displayData={`Subtotal: ${convertStringToCurrency(((invoiceItem.unitQuantity * invoiceItem.unitCost) / 100).toFixed(2).toString())}
                             Tax Amount: ${convertStringToCurrency(((invoiceItem.unitQuantity * invoiceItem.unitCost) * (invoiceItem.taxRates.length === 0 ? 0 : Number(taxRates[0].percentage)) / (100 * 100)).toFixed(2).toString())}
                             Total: ${convertStringToCurrency(((invoiceItem.unitQuantity * invoiceItem.unitCost) * (1 + (invoiceItem.taxRates.length === 0 ? 0 : Number(taxRates[0].percentage)) / 100) / 100).toFixed(2).toString())}`}
                             confirmation={() => {
                                 confirmationAction(invoiceItem)
                             }} closeModal={closeDeleteConfirmation}/>}
        </>
    );
}

export default authGuard(InvoiceLineItemTable);
