import axiosInstance from "../axiosConfig";
import { Invoice } from "../models/Invoice";
import { CreateCreditNoteRequest } from "../models/CreateCreditNoteRequest";
import { CreditNote } from "../models/CreditNote";
import { PendingInvoiceItem } from "../models/PendingInvoice";
import { CreatePendingInvoiceItemRequest } from "../models/CreatePendingInvoiceItemRequest";
import { CreateOrphanInvoiceRequest } from "../models/CreateOrphanInvoiceRequest";
import { CreateInvoiceItemRequest } from "../models/CreateInvoiceItemRequest";
import { CreateRefundRequest } from "../models/CreateRefundRequest";
import { CreatePaymentIntentRequest } from "../models/CreatePaymentIntentRequest";
import { CreatePaymentIntentResponse } from "../models/CreatePaymentIntentResponse";
import { Customer } from "../models/Customer";
import { CustomerProfile } from "../models/CustomerProfile";
import { UpdateInvoiceItemRequest } from "../models/UpdateInvoiceItemRequest";
import { PaymentMethod } from "../models/PaymentMethod";
import { SupplementalPlan } from "../models/SupplementalPlan";
import { GetSupplementalPlansResponse } from "../models/GetSupplementalPlansResponse";
import { AddSupplementalPlanRequest } from "../models/AddSupplementalPlanRequest";
import { AddSupplementalPlanResponse } from "../models/AddSupplementalPlanResponse";
import { RemoveSupplementalPlanResponse } from "../models/RemoveSupplementalPlanResponse";
import { RemoveSupplementalPlanRequest } from "../models/RemoveSupplementalPlanRequest";
import { ProcessAllPaymentIntentRequest } from "../models/ProcessAllPaymentIntentRequest";
import { ProcessAllPaymentIntentResponse } from "../models/ProcessAllPaymentIntentResponse";
import { PaymentIntentsResponse } from "../models/PaymentIntentsResponse";
import { RefundsResponse } from "../models/RefundsResponse";

export const getBillingCustomerByEmailId = async (emailId: string) => {
  return await axiosInstance.get<Customer>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/customer?expand=subscription.defaultTaxRates`,
    {
      headers: {
        email: `${emailId}`,
      },
    },
  );
};
export const getCustomerProfileByEmail = async (userId: string) => {
  return await axiosInstance.get<CustomerProfile>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/profile`,
    {
      headers: {
        userId: `${userId}`,
      },
    },
  );
};
export const getAllInvoicesByCustomerId = async (billingCustomerId: string) => {
  return await axiosInstance.get<Invoice[]>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/invoices?customerId=${billingCustomerId}&expand=credits`,
  );
};
export const addCreditsNote = async (
  createCreditNoteRequest: CreateCreditNoteRequest,
) => {
  return await axiosInstance.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/credit-note`,
    createCreditNoteRequest,
  );
};
export const getAllCreditsNotesByInvoiceId = async (invoiceId: string) => {
  return await axiosInstance.get<CreditNote[]>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/credit-notes?invoiceId=${invoiceId}`,
  );
};
export const deleteCreditsNote = async (creditsNoteId: string) => {
  return await axiosInstance.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/credit-note/${creditsNoteId}`,
  );
};
export const getAllPendingInvoiceItemsByInvoiceId = async (invoiceId: string) => {
  return await axiosInstance.get<PendingInvoiceItem[]>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/pending-invoice-item/${invoiceId}`,
  );
};
export const addPendingInvoiceItem = async (
  createPendingInvoiceItemRequest: CreatePendingInvoiceItemRequest,
) => {
  return await axiosInstance.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/pending-invoice-item`,
    createPendingInvoiceItemRequest,
  );
};
export const deletePendingInvoiceItem = async (pendingInvoiceId: string) => {
  return await axiosInstance.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/pending-invoice-item/${pendingInvoiceId}`,
  );
};
export const saveOrphanInvoice = async (
  createOrphanInvoiceRequest: CreateOrphanInvoiceRequest,
) => {
  return await axiosInstance.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/invoice/orphan`,
    createOrphanInvoiceRequest,
  );
};
export const addInvoiceItem = async (
  createInvoiceItemRequest: CreateInvoiceItemRequest,
) => {
  return await axiosInstance.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/invoice-item`,
    createInvoiceItemRequest,
  );
};
export const updateInvoiceItem = async (
  id: string,
  updateInvoiceItemRequest: UpdateInvoiceItemRequest,
) => {
  return await axiosInstance.put(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/invoice-item/${id}`,
    updateInvoiceItemRequest,
  );
};
export const deleteInvoiceItem = async (id: string) => {
  return await axiosInstance.delete(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/invoice-item/${id}`,
  );
};
export const getRefundsByCustomerId = async (customerId: string) => {
  return await axiosInstance.get<RefundsResponse>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/refunds?customerId=${customerId}`,
  );
};
export const issueRefund = async (createRefundRequest: CreateRefundRequest) => {
  return await axiosInstance.post(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/refund`,
    createRefundRequest,
  );
};
export const processPayment = async (
  paymentIntentRequest: CreatePaymentIntentRequest,
) => {
  return await axiosInstance.post<CreatePaymentIntentResponse>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/payment-intent`,
    paymentIntentRequest,
  );
};
export const processAllPayments = async (
  processAllPaymentsRequest: ProcessAllPaymentIntentRequest,
) => {
  return await axiosInstance.post<ProcessAllPaymentIntentResponse>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/payment-intent/all`,
    processAllPaymentsRequest,
  );
};

export const getPaymentIntentsByCustomerId = async (customerId: string) => {
  return await axiosInstance.get<PaymentIntentsResponse>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/payment-intents?customerId=${customerId}`,
  );
};

export const finalizeInvoice = async (invoiceId: string) => {
  return await axiosInstance.put(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/invoice/${invoiceId}/finalize`,
  );
};

export const voidInvoice = async (invoiceId: string) => {
  return await axiosInstance.put(`${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/invoice/${invoiceId}/void`);
};

export const getPaymentMethodsByCustomerId = async (id: string) => {
  return await axiosInstance.get<PaymentMethod[]>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/payment-methods?customerId=${id}`,
  );
};

export const getSupplementalPlansByProduct = async (productId: string) => {
  return await axiosInstance.get<SupplementalPlan[]>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/supplemental-plans?productId=${productId}`,
  );
};

export const getSupplementalPlansBySubscription = async (
  subscriptionId: string,
) => {
  return await axiosInstance.get<GetSupplementalPlansResponse>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/subscription/supplemental-plans`,
    { headers: { "subscription-id": subscriptionId } },
  );
};

export const addSupplementalPlanToSubscription = async (
  request: AddSupplementalPlanRequest,
) => {
  return await axiosInstance.post<AddSupplementalPlanResponse>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/subscription/supplemental-plan`,
    request,
  );
};

export const removeSupplementalPlanFromSubscription = async (
  request: RemoveSupplementalPlanRequest,
) => {
  return await axiosInstance.delete<RemoveSupplementalPlanResponse>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/billing/subscription/supplemental-plan`,
    { data: request },
  );
};