import authGuard from "../../../shared/components/AuthGuard";
import classes from "../creditsNote/CreditsNote.module.scss";
import React, {useEffect, useState} from "react";
import {InvoiceStatus} from "../../../models/InvoiceStatus";
import AddCreditNoteView from "./AddCreditNoteView";
import {Invoice} from "../../../models/Invoice";
import InvoiceTable from "../../form/components/invoice/InvoiceTable";
import * as billingService from "../../../services/BillingService";
import {HTTP_STATUS} from "../../../models/HttpStatus";
import {ErrorType} from "../../../models/ErrorType";
import {useCustomerContext} from "../landingPage/useLandingPage";

const CreditsNote = ({disableVirtualization}) => {
    const state = useCustomerContext();
    const [openInvoices, setOpenInvoices] = useState<Invoice[]>([]);
    const [openCreditNote, setOpenCreditNote] = useState(false);
    const [invoice, setInvoice] = useState<Invoice>(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        (async () => { await getAllInvoices();})();
    }, []);

    async function getAllInvoices() {
        const invoicesResponse = await billingService.getAllInvoicesByCustomerId(state.id);
        if (invoicesResponse.status !== HTTP_STATUS.OK) {
            setError({
                message: `Could not retrieve invoices for billing Customer Id = ${state.id}`,
                errorType: ErrorType.ERROR
            });
            return null;
        }
        if (invoice && invoicesResponse.data && invoicesResponse.data.length !== 0) {
            const updatedInvoice = invoicesResponse.data.find(item => item.id === invoice.id)
            setInvoice(updatedInvoice)
        }
        setOpenInvoices(invoicesResponse.data.filter(invoice => invoice.status === InvoiceStatus.OPEN));
    }

    const handleOnSubmit = async () => {
        await getAllInvoices();
    };

    const onInvoiceSelection = (invoice) => {
        setInvoice(invoice);
        setOpenCreditNote(invoice.status === InvoiceStatus.OPEN);
    }

    return (
        <div>
            <div className={classes.container}>
                {openInvoices &&
                    <InvoiceTable invoices={openInvoices} onInvoiceSelection={onInvoiceSelection} classes={classes}
                                  disableVirtualization={disableVirtualization}/>}
                {invoice && <AddCreditNoteView invoice={invoice} isEditable={openCreditNote}
                                               handleOnSubmit={handleOnSubmit}
                                               disableVirtualization={disableVirtualization}/>}
                <div hidden={!error} className={classes.deleteBtn}>
                    <p>{error}</p>
                </div>
            </div>
        </div>
    );
}
export default authGuard(CreditsNote);