import authGuard from "../../../../../shared/components/AuthGuard";
import classes from "../CreditsNoteModal.module.scss";
import { Box, Modal } from "@mui/material";
import { Button, Form } from "../../../FormComponents";
import React, { useState } from "react";
import { useCustomerContext } from "../../../../billingService/landingPage/useLandingPage";
import * as billingService from "../../../../../services/BillingService";
import PlanDurationField from "../../PlanDurationField";
import { SupplementalPlanGroup } from "../../../../../models/SupplementalPlanGroup";
import { getEndDate } from "../../../../../utils/CommonUtils";

const RemoveAddonModal = ({ onSubmit, closeModal, supplementalPlanMap }) => {
    const state = useCustomerContext();
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [allFieldsDirty, setAllFieldsDirty] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [error, setError] = useState(null);

    const onSubmitForm = async (data) => {
        setError(null);
        if (enableSubmit) return;

        setEnableSubmit(true);

        const removeSupplementalPlanRequest = {
            subscriptionId: state.subscriptionId,
            supplementalGroupCode: supplementalPlanMap.supplementalGroupCode,
            endDate: getEndDate(data.planDuration || 1),
            metadata: { planIterations: data.planDuration || 1 },
        };

        try {
            await billingService.removeSupplementalPlanFromSubscription(removeSupplementalPlanRequest);
            setEnableSubmit(false);
            onSubmit();
            closeModal();
        } catch (ex) {
            setError(`Remove supplemental plan failed : ${ex}`);
            setEnableSubmit(false);
        }
    };

    return (
        <div
            data-testid="main-div-testid"
            className={classes.container}
            onClick={(e) => {
                const target = e.target as HTMLElement;
                if (target.className === classes.container) closeModal();
            }}
        >
            <Modal open={true} onClose={closeModal}>
                <Box className={classes.box}>
                    <Form
                        onSubmit={onSubmitForm}
                        defaultValues={defaultValues}
                        allFieldsDirty={allFieldsDirty}
                        setAllFieldsDirty={setAllFieldsDirty}
                    >
                        <div className={classes.formGroup}>
                            <label htmlFor="plans">End Add-on - {supplementalPlanMap?.supplementalGroupCode}</label>
                            <PlanDurationField disabled={supplementalPlanMap?.supplementalGroupCode === SupplementalPlanGroup.WFTP} />
                        </div>
                        <Button text={"Submit"} enableSubmit={enableSubmit} className={classes.btn} />
                    </Form>
                    <div hidden={!error} className={classes.deleteBtn}>
                        <p>{error}</p>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default authGuard(RemoveAddonModal);
