import authGuard from "../../../shared/components/AuthGuard";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {currencyValueFormatter} from "../../../utils/CommonUtils";
import React from "react";
import {DelinquentCustomer} from "../../../models/DelinquentCustomer";

const DelinquentsTable = ({customers, classes}) => {
    const columns: GridColDef[] = [
        {field: "customerName", headerName: "Customer Name", width: 200},
        {field: "customerEmail", headerName: "Customer Email", width: 200},
        {field: "phoneNumber", headerName: "Phone Number", width: 150},
        {field: "market", headerName: "Market", width: 150},
        {field: "amountDue", headerName: "Amount Due", width: 150, valueFormatter: currencyValueFormatter},
        {field: "defaultScore", headerName: "Default Score", width: 150},
        {field: "stripeInvoiceId", headerName: "Stripe Invoice ID", width: 250},
        {field: "id", headerName: "Stripe Customer ID", width: 250}
    ];

    const getCustomerDisplayData = (customers: DelinquentCustomer[]) => {
        return customers.map(customer => {
            return {
                customerName: customer.customerName,
                customerEmail: customer.customerEmail,
                phoneNumber: customer.phoneNumber,
                market: customer.market,
                amountDue: customer.amountDue,
                defaultScore: customer.defaultScore?.toFixed(2),
                stripeInvoiceId: customer.stripeInvoiceId,
                id: customer.stripeCustomerId,
            }
        })
    }

    return (
        <>
            {customers && customers.length !== 0 &&
                <div className={classes.table}>
                    <DataGrid
                        rows={getCustomerDisplayData(customers)}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 10},
                            },
                        }}
                        pageSizeOptions={[10, 20, 50]}
                        disableColumnSelector
                    />
                </div>
            }
        </>
    );
}

export default authGuard(DelinquentsTable);