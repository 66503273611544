import React, { memo } from "react";
import { Input } from "../FormComponents";
import classes from "./LandingPageFields/LandingPageFormFields.module.scss";
import { InputModel } from "../models";
import { useFormContext } from "react-hook-form";

const Child: React.FC<any> = memo(({ register, formState: { errors }, disabled }) => (
    <>
        <Input
            register={register}
            name="planDuration"
            title="Enter number of weeks:"
            placeholder="1"
            defaultValue="1"
            maxLength={128}
            data-testid="planDuration-testid"
            labelClassName={classes.input_label}
            errors={errors}
            lightColor={true}
            disabled={disabled}
            readOnly={false}
            inputType="number"
        />
    </>
));

const PlanDurationField: React.FC<InputModel> = ({ disabled = false }) => {
    const methods = useFormContext();
    return <Child {...methods} disabled={disabled} />;
};
export default PlanDurationField;
