import axios, { AxiosError } from "axios";
import { loginRequest, msalInstance } from "./authConfig";
import { toaster } from "./shared/util/Toaster";

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(async (request) => {
    const account = msalInstance.getAllAccounts()[0];
    const accessTokenRequest = {
        ...loginRequest,
        account: account,
    };

    await msalInstance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
            request.headers.common.Authorization = `Bearer ${accessTokenResponse.idToken}`;
        })
        .catch(console.error);

    request.headers["x-api-key"] = window.__RUNTIME_CONFIG__.REACT_APP_API_KEY;
    return request;
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
       const {status} = error.response;
       const code = status.toString();
        if (status === 408 || status === 504) {
            toaster.burnt({ code, message: "Please try again" });
        } else {
            toaster.burnt({ code, message: error.message });
        }
        throw error;
    },
);

export default axiosInstance;
