import authGuard from "../../../shared/components/AuthGuard";
import React, { useEffect, useState } from "react";
import classes from "../issueRefund/IssueRefund.module.scss";
import * as billingService from "../../../services/BillingService";
import { CreateRefundRequest } from "../../../models/CreateRefundRequest";
import { convertCurrencyToNumber } from "../../../utils/CommonUtils";
import { HTTP_STATUS } from "../../../models/HttpStatus";
import { useCustomerContext } from "../landingPage/useLandingPage";
import RefundModal from "../../form/components/modal/refund/RefundModal";
import { RefundStatus } from "../../../models/RefundStatus";
import { PaymentIntent } from "../../../models/PaymentIntent";
import { PaymentStatus } from "../../../models/PaymentStatus";
import PaymentIntentsTable from "../../form/components/modal/refund/PaymentIntentsTable";
import { Refund } from "../../../models/Refund";

const IssueRefund = ({ disableVirtualization }) => {
  const state = useCustomerContext();
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>(null);
  const [paymentIntents, setPaymentIntents] = useState<PaymentIntent[]>(null);
  const [refunds, setRefunds] = useState<Refund[]>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    (async () => {
      await getAllPaymentIntents();
      await getAllRefunds();
    })();
  }, []);

  async function getAllPaymentIntents() {
    const paymentIntentsResponse =
      await billingService.getPaymentIntentsByCustomerId(state.id);
    if (paymentIntentsResponse.status !== HTTP_STATUS.OK) {
      setError(
        `Could not retrieve payment intents for billing Customer Id = ${state.id}`,
      );
      return null;
    }
    setPaymentIntents(
      paymentIntentsResponse.data.data.filter(
        (paymentIntent) => paymentIntent.status === PaymentStatus.SUCCESS,
      ),
    );
  }

  async function getAllRefunds() {
    const refundsResponse = await billingService.getRefundsByCustomerId(
      state.id,
    );
    if (refundsResponse.status !== HTTP_STATUS.OK) {
      setError(
        `Could not retrieve refunds for billing Customer Id = ${state.id}`,
      );
      return null;
    }
    setRefunds(
      refundsResponse.data.data.filter(
        (refund) => refund.status === RefundStatus.SUCCESS,
      ),
    );
  }

  const closeModal = () => {
    setModalOpen(false);
  };

  async function onSubmitForm(data) {
    setError(null);
    setSuccess(null);
    if (enableSubmit) return;

    setEnableSubmit(true);
    const createRefundRequest = {
      paymentIntentId: data.paymentIntentId,
      description: data.description,
      amount: convertCurrencyToNumber(data.amountCurrency.toString()),
      metadata: {},
    };
    issueRefund(createRefundRequest)
      .then((response) => {
        if (response.status !== HTTP_STATUS.OK) {
          setError(
            `Issue Refund request failed with status = ${response.status}`,
          );
          setEnableSubmit(true);
          return;
        }
        if (response.data.data.status !== RefundStatus.SUCCESS) {
          setError(`Issue Refund posted, but with failure status`);
          setEnableSubmit(true);
          return;
        }
        setEnableSubmit(false);
        setSuccess(`Issue Refund posted successfully`);
        getAllRefunds();
      })
      .catch((ex) => {
        setError(`Issue Refund failed : ${ex}`);
        setEnableSubmit(false);
      });
    closeModal();
  }

  async function issueRefund(createRefundRequest: CreateRefundRequest) {
    return await billingService.issueRefund(createRefundRequest);
  }

  const onPaymentIntentSelection = (paymentIntent) => {
    setError(null);
    setSuccess(null);
    setEnableSubmit(false);
    setPaymentIntent(paymentIntent);
    setModalOpen(
      paymentIntent ? paymentIntent.status === PaymentStatus.SUCCESS : false,
    );
  };

  return (
    <div>
      <div className={classes.container}>
        {paymentIntents && refunds && (
          <PaymentIntentsTable
            paymentIntents={paymentIntents}
            refunds={refunds}
            onPaymentIntentSelection={onPaymentIntentSelection}
            classes={classes}
            disableVirtualization={disableVirtualization}
          />
        )}
        {modalOpen && (
          <RefundModal
            paymentIntent={paymentIntent}
            onSubmitForm={onSubmitForm}
            enableSubmit={enableSubmit}
            closeModal={closeModal}
          />
        )}

        <div hidden={!error} className={classes.deleteBtn}>
          <p>{error}</p>
        </div>
        <div hidden={!success} className={classes.successBtn}>
          <p>{success}</p>
        </div>
      </div>
    </div>
  );
};

export default authGuard(IssueRefund);
