import React, {useEffect, useRef, useState} from 'react';
import classes from "./CreditsReportPage.module.scss";
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Tag} from "primereact/tag";
import {Button} from "primereact/button";
import {FilterMatchMode} from "primereact/api";
import {getAllCreditReport} from "../../../services/ReservationsService";
import {CompleteStatus, CreditCheckResult, CreditDetailsWithResults} from "../../../models/CreditDetailsWithResults";
import {Dropdown} from "primereact/dropdown";
import {TriStateCheckbox} from "primereact/tristatecheckbox";
import {saveAs} from 'file-saver';

const CreditReportPage = () => {

    const [creditDetailsWithResults, setCreditDetailsWithResults] = useState<CreditDetailsWithResults[]>([]);
    const [selectedCreditDetailsWithResults, setSelectedCreditDetailsWithResults] = useState<CreditDetailsWithResults[]>([]);
    const [loading, setLoading] = useState(true);
    const dt = useRef<DataTable<any>>(null);
    const [checkResultStatuses] = useState(['PASS', 'FAIL']);
    const [completionCheckStatuses] = useState(['COMPLETE', 'INCOMPLETE']);
    const [filters, setFilters] = useState({
        reservationId: {value: null, matchMode: FilterMatchMode.CONTAINS},
        checkCompleteStatus: {value: null, matchMode: FilterMatchMode.EQUALS},
        checkResult: {value: null, matchMode: FilterMatchMode.EQUALS},
        experianErrorToVRMappingScreen: {value: null, matchMode: FilterMatchMode.CONTAINS},
        userId: {value: null, matchMode: FilterMatchMode.CONTAINS},
        'score.value': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'score.timestamp': {value: null, matchMode: FilterMatchMode.CONTAINS},
        errorString: {value: null, matchMode: FilterMatchMode.CONTAINS},
        lastModified: {value: null, matchMode: FilterMatchMode.CONTAINS},
        reasonOfFailure: {value: null, matchMode: FilterMatchMode.CONTAINS},
        version: {value: null, matchMode: FilterMatchMode.CONTAINS},
        skippedAutoLoanRule: {value: null, matchMode: FilterMatchMode.EQUALS},
        fraudAlerts: {value: null, matchMode: FilterMatchMode.CONTAINS},
    });
    const getSeverityForCheckResult = (status) => {
        switch (status) {
            case CreditCheckResult.PASS:
                return 'success';
            case CreditCheckResult.FAIL:
                return 'danger';
            default :
                return 'warning'
        }
    };

    const getSeverityForCompleteStatus = (status) => {
        switch (status) {
            case CompleteStatus.COMPLETE:
                return null;
            case CompleteStatus.INCOMPLETE:
                return 'warning';
            default :
                return 'danger'
        }
    };

    useEffect(() => {
        getAllCreditReport().then(creditInfoResponse => {
            if (creditInfoResponse?.status !== 200) {
                console.error('Error fetching credit info: ', creditInfoResponse?.status);
                return;
            }
            setCreditDetailsWithResults(creditInfoResponse?.data)
            setLoading(false)
        }).catch(error => {
            console.error('Error fetching credit info: ', error);
            setLoading(false)
        });
    }, []);

    const stockBodyTemplate = (rowData) => {
        const stockClassName = classNames('border-circle w-2rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm', {
            'bg-red-100 text-red-900': rowData.checkResult == CreditCheckResult.FAIL,
            'bg-teal-100 text-teal-900': rowData.checkResult == CreditCheckResult.PASS,
        });
        return <div className={stockClassName}>{rowData.score.value}</div>;
    };

    const creditCheckBodyTemplate = (rowData) => {
        return <Tag value={rowData.checkResult} severity={getSeverityForCheckResult(rowData.checkResult)}></Tag>;
    };

    const checkCompleteStatusBodyTemplate = (rowData) => {
        return <Tag value={rowData.checkCompleteStatus}
                    severity={getSeverityForCompleteStatus(rowData.checkCompleteStatus)}></Tag>;
    };

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({selectionOnly});
    };

    const saveAsExcelFile = (buffer, fileName) => {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {type: EXCEL_TYPE});
        saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    };

    const statusRowFilterTemplateForCheckResult = (options) => {
        return (
            <Dropdown value={options.value} options={checkResultStatuses}
                      onChange={(e) => options.filterApplyCallback(e.value)}
                      itemTemplate={statusItemTemplateForCheckResult} placeholder="Select One"
                      className="p-column-filter" showClear style={{minWidth: '12rem'}}/>
        );
    };

    const statusItemTemplateForCheckResult = (option) => {
        return <Tag value={option} severity={getSeverityForCheckResult(option)}/>;
    };

    const statusRowFilterTemplateForCompleteStatus = (options) => {
        return (
            <Dropdown value={options.value} options={completionCheckStatuses}
                      onChange={(e) => options.filterApplyCallback(e.value)}
                      itemTemplate={statusItemTemplateForCompleteStatus} placeholder="Select One"
                      className="p-column-filter" showClear style={{minWidth: '12rem'}}/>
        );
    };

    const statusItemTemplateForCompleteStatus = (option) => {
        return <Tag value={option} severity={getSeverityForCompleteStatus(option)}/>;
    };

    const ruleSkippedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', {
            'true-icon pi-check-circle': rowData.skippedAutoLoanRule,
            'false-icon pi-times-circle': !rowData.skippedAutoLoanRule
        })}></i>;
    };

    const ruleSkippedFilterTemplate = (options) => {
        return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)}/>;
    };

    const header = (
        <>
            {
                selectedCreditDetailsWithResults.length > 0 && (
                    <div className="flex justify-content-between">
                        <div className="flex align-items-center justify-content-start gap-2">
                            {selectedCreditDetailsWithResults.length} / {creditDetailsWithResults.length}
                        </div>

                        <div className="flex align-items-center justify-content-end gap-2">
                            <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(true)}
                                    data-pr-tooltip="CSV"/>
                        </div>
                    </div>)
            }
        </>
    );

    const CreditReportTable = () => {
        return (
            <DataTable value={creditDetailsWithResults}
                       selectionMode={null}
                       selection={selectedCreditDetailsWithResults}
                       onSelectionChange={(e) => setSelectedCreditDetailsWithResults(e.value)}
                       dataKey="userId"
                       filters={filters} filterDisplay="row"
                       loading={loading}
                       emptyMessage="No customers found."
                       header={header}
                       scrollable
                       ref={dt}
                       paginator rows={10}
                       rowsPerPageOptions={[5, 10, 25, 50]}
                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                       currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            >
                <Column selectionMode="multiple" headerStyle={{width: '3rem'}} frozen></Column>
                <Column filter filterPlaceholder="Search by UserId"
                        field="userId" header="User Id"
                        style={{minWidth: '16rem'}} sortable></Column>
                <Column filter filterPlaceholder="Search"
                        field="score.value" header="Score Value"
                        body={stockBodyTemplate}
                        style={{minWidth: '12rem'}} sortable></Column>
                <Column filter filterPlaceholder="Search"
                        field="score.timestamp" header="Score Timestamp"
                        style={{minWidth: '12rem'}} sortable></Column>
                <Column filter showFilterMenu={false} filterMenuStyle={{width: '12rem'}}
                        filterElement={statusRowFilterTemplateForCompleteStatus}
                        field="checkCompleteStatus" header="Completion Status"
                        body={checkCompleteStatusBodyTemplate}
                        style={{minWidth: '12rem'}}
                        sortable></Column>
                <Column filter showFilterMenu={false} filterMenuStyle={{width: '12rem'}}
                        filterElement={statusRowFilterTemplateForCheckResult}
                        field="checkResult" header="Check Result"
                        body={creditCheckBodyTemplate}
                        style={{minWidth: '16rem'}}
                        sortable></Column>
                <Column filter filterPlaceholder="Search"
                        field="errorString" header="Error Message"
                        style={{minWidth: '16rem'}} sortable></Column>
                <Column filter filterPlaceholder="Search"
                        field="experianErrorToVRMappingScreen" header="Experian Error"
                        style={{minWidth: '16rem'}}
                        sortable></Column>
                <Column filter filterPlaceholder="Search"
                        field="lastModified" header="Last Modified"
                        style={{minWidth: '16rem'}} sortable></Column>
                <Column filter filterPlaceholder="Search"
                        field="reasonOfFailure" header="Reason Of Failure"
                        style={{minWidth: '16rem'}} sortable></Column>
                <Column filter filterPlaceholder="Search"
                        field="version" header="Version"
                        style={{minWidth: '16rem'}} sortable></Column>
                <Column filter filterPlaceholder="Search" filterElement={ruleSkippedFilterTemplate}
                        field="skippedAutoLoanRule" header="Is Auto Loan Rule Skipped?"
                        dataType="boolean" body={ruleSkippedBodyTemplate}
                        style={{minWidth: '16rem'}} sortable></Column>
                <Column filter filterPlaceholder="Search"
                        field="fraudAlerts" header="Fraud Alerts"
                        style={{minWidth: '16rem'}} sortable></Column>
                <Column filter filterPlaceholder="Search"
                        field="reservationId" header="Reservation Id"
                        style={{minWidth: '12rem'}} sortable className="font-bold"></Column>
            </DataTable>
        );
    }

    return (
        <div className={classes.container}>
            <div className={classes.title}>
                <label>Credit Report</label>
            </div>
            <CreditReportTable/>
        </div>
    );
};

export default CreditReportPage;