export interface CreditDetailsWithResults {
    reservationId: string,
    checkCompleteStatus: CompleteStatus,
    checkResult: CreditCheckResult,
    experianErrorToVRMappingScreen: string,
    userId: string,
    score: CreditScore,
    errorString: string,
    lastModified: string,
    reasonOfFailure: [],
    version: string,
    skippedAutoLoanRule: boolean,
    fraudAlerts: []
}

export enum CompleteStatus {
    COMPLETE = 'COMPLETE',
    INCOMPLETE = 'INCOMPLETE'
}


export enum CreditCheckResult {
    PASS = 'PASS',
    FAIL = 'FAIL'
}

export interface CreditScore {
    value: number,
    timestamp: string
}