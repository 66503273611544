import React, {memo} from "react";
import {Input} from "../FormComponents";
import classes from "./LandingPageFields/LandingPageFormFields.module.scss";
import {InputModel} from "../models";
import {useFormContext} from "react-hook-form";

const Child: React.FC<any> = memo(({ register, defaultValue, formState: { errors }, disabled }) => (
    <>
        <Input
            register={register}
            name="dueDate"
            title="Enter Due Date:"
            placeholder="12/12/2022"
            maxLength={128}
            data-testid="dueDate-testid"
            labelClassName={classes.input_label}
            errors={errors}
            lightColor={true}
            disabled={disabled}
            readOnly={false}
            inputType = 'date'
            maxValue = '9999-12-31'
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: true,
                    message: 'Due Date is required',
                }
            }}
        />
    </>
));

const DueDateField: React.FC<InputModel> = ({ disabled = false }) => {
    const methods = useFormContext();
    return <Child {...methods} disabled={disabled} />;
};
export default DueDateField;