import authGuard from "../../../../../shared/components/AuthGuard";
import React, {useEffect, useState} from "react";
import classes from "../CreditsNoteModal.module.scss";
import {Box, Modal} from "@mui/material";
import {Button, Form} from "../../../FormComponents";
import {convertCurrencyToNumber, convertStringToCurrency} from "../../../../../utils/CommonUtils";
import {FaCcAmex, FaCcDiscover, FaCcMastercard, FaCcVisa} from "react-icons/fa";
import {useCustomerContext} from "../../../../billingService/landingPage/useLandingPage";
import Select from "react-select";
import NoteField from "../../NoteField";
import AmountCurrencyField from "../../AmountCurrencyField";

const ProcessPaymentModal = ({invoice, closeModal, onSubmit}) => {
    const state = useCustomerContext();
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [allFieldsDirty, setAllFieldsDirty] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState<string>(state.defaultPaymentMethodId);
    const [defaultValues, setDefaultValues] = useState({
        payment: state.defaultPaymentMethodId,
        description: null,
        metadata: {}
    });

    useEffect(() => {
        if (allFieldsDirty === enableSubmit) return;
        setEnableSubmit(allFieldsDirty);
    }, [allFieldsDirty]);

    const handleSubmit = (data, e) => {
        if (enableSubmit) return;
        setEnableSubmit(true);
        onSubmit(paymentMethod, convertCurrencyToNumber(data.amountCurrency.toString()), data.note, e.nativeEvent.submitter.id);
    };

    const cardBrandMap = {
        "visa": <FaCcVisa/>,
        "mastercard": <FaCcMastercard/>,
        "amex": <FaCcAmex/>,
        "discover": <FaCcDiscover/>
    };

    const options = state.paymentMethods.map(paymentMethod => {
        return {
            value: paymentMethod.id,
            label: (
                <div>{cardBrandMap[paymentMethod.details["brand"]]} &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; {paymentMethod.details["last4"]}</div>
            )
        };
    });

    return (
        <div data-testid="main-div-testid" className={classes.container} onClick={(e) => {
            const target = e.target as HTMLElement
            if (target.className === classes.container) closeModal()
        }}>
            <Modal open={true} onClose={closeModal}>
                <Box className={classes.box}>
                    <p>Stripe Invoice Number: {invoice.metadata["stripeInvoiceNumber"]}</p>
                    <p>Amount Due: {convertStringToCurrency((invoice.balance.amountDue / 100).toString())}</p>
                    <Form
                        onSubmit={handleSubmit}
                        defaultValues={defaultValues}
                        allFieldsDirty={allFieldsDirty}
                        setAllFieldsDirty={setAllFieldsDirty}
                    >
                        <AmountCurrencyField value={((invoice.balance.amountDue / 100).toString())}/>

                        <div className={classes.formGroup}>
                            <label htmlFor="payment-methods">Select Payment Methods:</label>
                            <Select id="payment-methods"
                                    name={"payment"}
                                    aria-label="payment-methods"
                                    value={options.find(option => option.value === paymentMethod)}
                                    defaultValue={options.find(option => option.value === state.defaultPaymentMethodId)}
                                    options={options}
                                    onChange={e => setPaymentMethod(e.value)}/>
                        </div>
                        <NoteField disabled={enableSubmit}/>
                        <Button id={"process-selected"} text={'Process Selected'} enableSubmit={enableSubmit}
                                className={classes.btn}/>
                        <Button id={"process-all"} text={'Process All'} enableSubmit={enableSubmit}
                                className={classes.btn}/>
                    </Form>
                </Box>
            </Modal>
        </div>
    );
};

export default authGuard(ProcessPaymentModal);
