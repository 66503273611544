import authGuard from "../../../../../shared/components/AuthGuard";
import classes from "../CreditsNoteModal.module.scss";
import { Button, Form } from "../../../FormComponents";
import DescriptionField from "../../CreditsNote/DescriptionField";
import UnitQuantityField from "../../UnitQuantityField";
import UnitCostField from "../../UnitCostField";
import TaxRatesField from "../../TaxRatesField";
import React, { useEffect, useState } from "react";
import { TaxBehavior } from "../../../../../models/TaxBehavior";
import * as billingService from "../../../../../services/BillingService";
import { CreateInvoiceItemRequest } from "../../../../../models/CreateInvoiceItemRequest";
import InvoiceIdField from "../../InvoiceIdField";
import StartDateField from "../../StartDateField";
import EndDateField from "../../EndDateField";
import { convertCurrencyToNumber, convertStringToCurrency } from "../../../../../utils/CommonUtils";
import { UpdateInvoiceItemRequest } from "../../../../../models/UpdateInvoiceItemRequest";
import InvoiceItemIdField from "../../InvoiceItemIdField";
import { Box, Modal } from "@mui/material";

const InvoiceItemModal = ({ invoice, invoiceItem, taxRates, edit = false, closeModal, onSubmit }) => {
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [allFieldsDirty, setAllFieldsDirty] = useState(false);
    const [taxBehavior, setTaxBehavior] = useState(TaxBehavior.INCLUSIVE);
    const [taxBehaviorEnable, setTaxBehaviorEnable] = useState(true);
    const [taxRatesValue, setTaxRatesValue] = useState(
        taxBehavior === TaxBehavior.INCLUSIVE ? null : taxRates.map((taxRate) => taxRate.id).join(","),
    );
    const [error, setError] = useState(null);

    const [defaultValues, setDefaultValues] = useState({
        invoiceId: invoice.id,
        invoiceItemId: edit ? invoiceItem.id : null,
        description: edit ? invoiceItem.description : null,
        unitQuantity: edit ? invoiceItem.unitQuantity : null,
        unitCost: edit ? convertStringToCurrency((invoiceItem.unitCost / 100).toString()) : null,
        startDate: edit ? invoiceItem.period.start : null,
        endDate: edit ? invoiceItem.period.end : null,
        taxBehavior: null,
        taxRates: taxRatesValue,
        metadata: {},
    });

    useEffect(() => {
        if (allFieldsDirty === enableSubmit) return;
        setEnableSubmit(allFieldsDirty);
    }, [allFieldsDirty]);

    async function onSubmitForm(data) {
        setError(null);
        if (enableSubmit) return;

        setEnableSubmit(true);
        if (edit) {
            const updateInvoiceRequest = {
                description: data.description,
                unitQuantity: data.unitQuantity,
                unitCost: convertCurrencyToNumber(data.unitCost.toString()),
                period:
                    data.startDate === null || data.endDate === null
                        ? null
                        : {
                              start: data.startDate,
                              end: data.endDate,
                          },
                taxBehavior: taxBehavior as TaxBehavior,
                taxRates: taxRatesValue === null || taxRatesValue.length === 0 ? [] : taxRatesValue.split(","),
                metadata: {},
            };
            updateInvoiceItem(data.invoiceItemId, updateInvoiceRequest)
                .then((data) => {
                    if (data.status !== 200) {
                        setError(`Updating Invoice Line Item failed with status = ${data.status}`);
                        setEnableSubmit(true);
                        return;
                    }
                    setEnableSubmit(false);
                    onSubmit(data);
                    closeModal();
                })
                .catch((ex) => {
                    setError(`Updating Invoice Line Item failed : ${ex}`);
                    setEnableSubmit(false);
                });
        } else {
            const createInvoiceItemRequest = {
                invoiceId: invoice.id,
                description: data.description,
                unitQuantity: data.unitQuantity,
                unitCost: convertCurrencyToNumber(data.unitCost.toString()),
                period:
                    data.startDate === null || data.endDate === null
                        ? null
                        : {
                              start: data.startDate,
                              end: data.endDate,
                          },
                taxBehavior: taxBehavior as TaxBehavior,
                taxRates: taxRatesValue === null || taxRatesValue.length === 0 ? [] : taxRatesValue.split(","),
                metadata: {},
            };
            addInvoiceItem(createInvoiceItemRequest)
                .then((data) => {
                    if (data.status !== 201) {
                        setError(`Adding Invoice Line Item failed with status = ${data.status}`);
                        setEnableSubmit(true);
                        return;
                    }
                    setEnableSubmit(false);
                    onSubmit(data);
                    closeModal();
                })
                .catch((ex) => {
                    setError(`Adding Invoice Line Item failed : ${ex}`);
                    setEnableSubmit(false);
                });
        }
    }

    async function addInvoiceItem(createInvoiceItemRequest: CreateInvoiceItemRequest) {
        return await billingService.addInvoiceItem(createInvoiceItemRequest);
    }

    async function updateInvoiceItem(id: string, updateInvoiceItemRequest: UpdateInvoiceItemRequest) {
        return await billingService.updateInvoiceItem(id, updateInvoiceItemRequest);
    }

    function onTaxBehaviorChange(event) {
        setTaxBehavior(event.target.value);
        setTaxBehaviorEnable(event.target.value === "INCLUSIVE");
        setTaxRatesValue(taxBehaviorEnable ? taxRates.map((taxRate) => taxRate.id).join(",") : "");
    }

    function onChangeHandler(event) {
        setTaxRatesValue(event.target.value);
    }

    return (
        <div
            data-testid="main-div-testid"
            className={classes.container}
            onClick={(e) => {
                const target = e.target as HTMLElement;
                if (target.className === classes.container) closeModal();
            }}
        >
            <Modal open={true} onClose={closeModal}>
                <Box className={classes.box}>
                    <Form
                        onSubmit={onSubmitForm}
                        defaultValues={defaultValues}
                        allFieldsDirty={allFieldsDirty}
                        setAllFieldsDirty={setAllFieldsDirty}
                    >
                        <InvoiceIdField disabled={true} />
                        {edit && <InvoiceItemIdField disabled={true} />}
                        <div className={classes.formGroup}>
                            <label htmlFor="tax-behavior">Select Tax Behavior:</label>
                            <select
                                required
                                id="tax-behavior"
                                data-testid="tax-behavior-test-id"
                                name="tax-behavior"
                                value={taxBehavior}
                                onChange={(event) => onTaxBehaviorChange(event)}
                            >
                                <optgroup label="Behaviors">
                                    <option value="INCLUSIVE">INCLUSIVE</option>
                                    <option value="EXCLUSIVE" disabled={taxRates?.length == 0}>
                                        EXCLUSIVE
                                    </option>
                                </optgroup>
                            </select>
                        </div>
                        <DescriptionField disabled={enableSubmit} />
                        <UnitQuantityField disabled={enableSubmit} />
                        <UnitCostField disabled={enableSubmit} />
                        <StartDateField disabled={enableSubmit} />
                        <EndDateField disabled={enableSubmit} />
                        <TaxRatesField disabled={true} value={taxRatesValue} onChangeHandler={onChangeHandler} rulesMandatory={false} />
                        <Button text={"Submit"} enableSubmit={enableSubmit} className={classes.btn} />
                    </Form>
                    <div hidden={!error} className={classes.deleteBtn}>
                        <p>{error}</p>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default authGuard(InvoiceItemModal);
