import authGuard from "../../../shared/components/AuthGuard";
import classes from "./Invoice.module.scss";
import React, {useState} from "react";
import InvoiceItemModal from "../../form/components/modal/invoiceItem/InvoiceItemModal";
import InvoiceLineItemTable from "../../form/components/invoice/InvoiceLineItemTable";
import DialogModal from "../../form/components/modal/confirmation/DialogModal";
import * as billingService from "../../../services/BillingService";
import {InvoiceStatus} from "../../../models/InvoiceStatus";
import {convertStringToCurrency} from "../../../utils/CommonUtils";
import {useCustomerContext} from "../landingPage/useLandingPage";

const AddInvoiceLineItemView = ({invoice, taxRates, isEditable, handleOnSubmit, disableVirtualization}) => {
    const state = useCustomerContext();
    const [openInvoiceItemModal, setOpenInvoiceItemModal] = useState(false);
    const [openFinalizeModal, setOpenFinalizeModal] = useState(false);

    function closeModal() {
        setOpenFinalizeModal(false);
    }

    async function confirmationAction() {
        finalizeInvoice(invoice.id).then((data) => {
            if (data.status !== 200) {
                return;
            }
            handleOnSubmit();
            closeModal();
        }).catch((ex) => {
            closeModal();
        });
    }

    async function finalizeInvoice(invoiceId: string) {
        return await billingService.finalizeInvoice(invoiceId);
    }

    return (
        <>
            <div className={classes.divAdd}>
                <button data-testid="add-invoice-item-test-id"
                        onClick={() => setOpenInvoiceItemModal(!openInvoiceItemModal)}
                        disabled={!isEditable}
                        className={`${classes.button} ${isEditable === true ? classes.enable_bg_color : classes.disable_bg_color}`}>Add
                    Invoice Item
                </button>
                <button data-testid="finalize-invoice-test-id" onClick={() => setOpenFinalizeModal(!openFinalizeModal)}
                        disabled={!isEditable}
                        className={`${classes.button} ${isEditable === true ? classes.enable_bg_color : classes.disable_bg_color}`}>Finalize
                    Invoice
                </button>
            </div>
            {openInvoiceItemModal && <InvoiceItemModal invoice={invoice} taxRates={taxRates}
                                                       closeModal={() => setOpenInvoiceItemModal(false)}
                                                       onSubmit={handleOnSubmit}/>}
            {invoice &&
                <InvoiceLineItemTable invoice={invoice} taxRates={taxRates} classes={classes} onSubmit={handleOnSubmit}
                                      disableVirtualization={disableVirtualization}/>}
            {invoice && invoice.status === InvoiceStatus.DRAFT && openFinalizeModal && <DialogModal title="Finalize"
                                                                                                    dialogTitle={`Finalize Invoice - ${invoice.id}`}
                                                                                                    displayData={`Customer Name: ${state.displayName}
                                                                                                    Description: ${invoice.description}
                                                                                                    Subtotal: ${convertStringToCurrency((invoice.items.reduce((n, {
                                                                                                        unitCost,
                                                                                                        unitQuantity
                                                                                                    }) => n + unitCost * unitQuantity, 0) / 100).toString())}
                                                                                                    Tax Amount: ${convertStringToCurrency((invoice.tax / 100).toString())}
                                                                                                    Total: ${convertStringToCurrency(((invoice.items.reduce((n, {
                                                                                                        unitCost,
                                                                                                        unitQuantity
                                                                                                    }) => n + unitCost * unitQuantity, 0) + invoice.tax) / 100).toString())}
                                                                                                    Amount Due: ${convertStringToCurrency((invoice.balance.amountDue / 100).toString())}`}
                                                                                                    confirmation={confirmationAction}
                                                                                                    closeModal={closeModal}/>}
        </>
    );
}

export default authGuard(AddInvoiceLineItemView);