import axiosInstance from "../axiosConfig";

interface RequestBody {
    reservations: string[];
}

export const getCreditReport = async (data: string) => {
    const idsArray = data.split('\n').map(id => id.trim());
    let requestBody : RequestBody = {
        reservations: idsArray
    };
    return await axiosInstance.post(`${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/operations/credit`, requestBody);
}
