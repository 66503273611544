import {toast} from "react-hot-toast";
import {ToastInfo} from "../../models/ToastInfo";

export const toaster = {
    burnt: (toastInfo: ToastInfo) => toast.error(
        'Status: ' + toastInfo.code + '\n' + 'Message: ' + toastInfo.message,
        {duration: Infinity}),
    tasty: () => toast.success('Success'),
    loading: () => toast.loading('...loading')
}


