import authGuard from "../../../../../shared/components/AuthGuard";
import classes from "../CreditsNoteModal.module.scss";
import { Button, Form } from "../../../FormComponents";
import DescriptionField from "../../CreditsNote/DescriptionField";
import React, { useEffect, useState } from "react";
import { CreatePendingInvoiceItemRequest } from "../../../../../models/CreatePendingInvoiceItemRequest";
import { TaxBehavior } from "../../../../../models/TaxBehavior";
import * as billingService from "../../../../../services/BillingService";
import BillingCustomerIdField from "../../BillingCustomerIdField";
import UnitQuantityField from "../../UnitQuantityField";
import UnitCostField from "../../UnitCostField";
import TaxRatesField from "../../TaxRatesField";
import { convertCurrencyToNumber } from "../../../../../utils/CommonUtils";
import { Box, Modal } from "@mui/material";

const PendingInvoiceItemModal = ({ pendingInvoiceItem, taxRates, closeModal, onSubmit }) => {
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [allFieldsDirty, setAllFieldsDirty] = useState(false);
    const [taxBehavior, setTaxBehavior] = useState(TaxBehavior.INCLUSIVE);
    const [taxBehaviorEnable, setTaxBehaviorEnable] = useState(true);
    const [taxRatesValue, setTaxRatesValue] = useState(taxBehavior === TaxBehavior.INCLUSIVE ? null : taxRates);
    const [error, setError] = useState(null);
    const [defaultValues] = useState({
        customerId: pendingInvoiceItem.billingCustomerId,
        description: null,
        unitQuantity: null,
        unitCost: null,
        effectiveDate: new Date(),
        taxBehavior: null,
        taxRates: taxRatesValue,
        metadata: {},
    });

    useEffect(() => {
        if (allFieldsDirty === enableSubmit) return;
        setEnableSubmit(allFieldsDirty);
    }, [allFieldsDirty]);

    async function onSubmitForm(data) {
        setError(null);
        if (enableSubmit) return;

        setEnableSubmit(true);
        const createPendingInvoiceItemRequest = {
            customerId: pendingInvoiceItem.billingCustomerId,
            description: data.description,
            unitQuantity: data.unitQuantity,
            unitCost: convertCurrencyToNumber(data.unitCost.toString()),
            effectiveDate: new Date().toISOString().split("T")[0],
            taxBehavior: taxBehavior as TaxBehavior,
            taxRates: taxRatesValue === null || taxRatesValue.length === 0 ? [] : taxRatesValue.split(","),
            metadata: {},
        };
        savePendingInvoiceItem(createPendingInvoiceItemRequest)
            .then((data) => {
                if (data.status !== 200) {
                    setError(`Add Pending Invoice Item failed with status = ${data.status}`);
                    setEnableSubmit(true);
                    return;
                }
                setEnableSubmit(false);
                onSubmit(data);
                closeModal();
            })
            .catch((ex) => {
                setError(`Add Pending Invoice Item failed : ${ex}`);
                setEnableSubmit(false);
            });
    }

    async function savePendingInvoiceItem(createPendingInvoiceItemRequest: CreatePendingInvoiceItemRequest) {
        return await billingService.addPendingInvoiceItem(createPendingInvoiceItemRequest);
    }

    function onTaxBehaviorChange(event) {
        setTaxBehavior(event.target.value);
        setTaxBehaviorEnable(event.target.value === TaxBehavior.INCLUSIVE);
        setTaxRatesValue(taxBehaviorEnable ? taxRates : "");
    }

    function onChangeHandler(event) {
        setTaxRatesValue(event.target.value);
    }

    return (
        <div
            data-testid="main-div-testid"
            className={classes.container}
            onClick={(e) => {
                const target = e.target as HTMLElement;
                if (target.className === classes.container) closeModal();
            }}
        >
            <Modal open={true} onClose={closeModal}>
                <Box className={classes.box}>
                    <Form
                        onSubmit={onSubmitForm}
                        defaultValues={defaultValues}
                        allFieldsDirty={allFieldsDirty}
                        setAllFieldsDirty={setAllFieldsDirty}
                    >
                        <BillingCustomerIdField disabled={true} />
                        <div className={classes.formGroup}>
                            <label htmlFor="tax-behavior">Select Tax Behavior:</label>
                            <select
                                required
                                id="tax-behavior"
                                data-testid="tax-behavior-test-id"
                                name="tax-behavior"
                                value={taxBehavior}
                                onChange={(event) => onTaxBehaviorChange(event)}
                            >
                                <optgroup label="Behaviors">
                                    <option value="INCLUSIVE">INCLUSIVE</option>
                                    <option value="EXCLUSIVE" disabled={taxRates?.length == 0}>
                                        EXCLUSIVE
                                    </option>
                                </optgroup>
                            </select>
                        </div>
                        <DescriptionField disabled={enableSubmit} />
                        <UnitQuantityField disabled={enableSubmit} />
                        <UnitCostField disabled={enableSubmit} allowNegativeValue={true} />
                        <TaxRatesField disabled={true} value={taxRatesValue} onChangeHandler={onChangeHandler} rulesMandatory={false} />
                        <Button text={"Submit"} enableSubmit={enableSubmit} className={classes.btn} />
                    </Form>
                    <div hidden={!error} className={classes.deleteBtn}>
                        <p>{error}</p>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default authGuard(PendingInvoiceItemModal);
