import authGuard from "../../shared/components/AuthGuard";
import React, {useEffect, useState} from "react";
import {DelinquentCustomer} from "../../models/DelinquentCustomer";
import * as delinquentsService from "../../services/DelinquentsService";
import {HTTP_STATUS} from "../../models/HttpStatus";
import classes from "../billingService/issueRefund/IssueRefund.module.scss";
import DelinquentsTable from "./table/DelinquentsTable";
import DelinquentsSummary from "./summary/DelinquentsSummary";
import OpsHeader from "../header/OpsHeader";
import Loader from "../../shared/components/Loader";

const Delinquents = () => {
    const [customers, setCustomers] = useState<DelinquentCustomer[]>([])
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [tableLoading, setTableLoading] = useState(true);

    useEffect(() => {
        (async () => {
            await getAllDelinquentCustomers();
        })();
    }, []);

    const filterDuplicates = (delinquentCustomers: DelinquentCustomer[]) => {
        if (delinquentCustomers?.length === 0){ return [];}
        return delinquentCustomers.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.stripeCustomerId === value.stripeCustomerId
            )));
    }

    async function getAllDelinquentCustomers() {
        setTableLoading(true);
        const customersResponse = await delinquentsService.getAllDelinquentCustomers();
        if (customersResponse.status !== HTTP_STATUS.OK) {
            setError(`Could not retrieve customer data for all delinquents`);
            return null;
        }
        const delinquentCustomers = filterDuplicates(customersResponse?.data?.unpaidInvoices);
        setCustomers(delinquentCustomers);
        setTableLoading(false);
    }

    return (
        <div>
            <OpsHeader/>
            {tableLoading && <Loader/>}

            <div className={classes.container}>
                <DelinquentsSummary customers={customers} refreshData={() => getAllDelinquentCustomers()}/>
                {customers &&
                    <DelinquentsTable customers={customers} classes={classes}/>}
                <div hidden={!error} className={classes.deleteBtn}>
                    <p>{error}</p>
                </div>
                <div hidden={!success} className={classes.successBtn}>
                    <p>{success}</p>
                </div>
            </div>
        </div>
    );
}

export default authGuard(Delinquents);
