import {Configuration, PublicClientApplication} from "@azure/msal-browser";

export const msalConfig: Configuration = {
    auth: {
        clientId: window.__RUNTIME_CONFIG__.REACT_APP_MSAL_OPERATOR_CLIENT_ID,
        authority: `${window.__RUNTIME_CONFIG__.REACT_APP_MSAL_OPERATOR_AUTHORITY}`,
        redirectUri: `${window.__RUNTIME_CONFIG__.REACT_APP_MSAL_OPERATOR_REDIRECT_URI}`,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    }
};

export const loginRequest = {
    scopes: [`${window.__RUNTIME_CONFIG__.REACT_APP_MSAL_OPERATOR_SCOPE}`]
};

export const msalInstance = new PublicClientApplication(msalConfig);
