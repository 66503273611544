import React from "react";
import {toast, ToastBar, Toaster} from "react-hot-toast";

const ToastNotification  = () => {
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <Toaster position={'top-right'}>
                {(t) => (
                    <ToastBar toast={t}>
                        {({ icon, message }) => (
                            <>
                                {icon}
                                {message}
                                {t.type === 'error' && (
                                    <button onClick={() => toast.dismiss(t.id)}>X</button>
                                )}
                            </>
                        )}
                    </ToastBar>
                )}
            </Toaster>
        </div>
    );
};

export default ToastNotification;
