import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import {Select} from '../../FormComponents';
import classes from './LandingPageFormFields.module.scss'
import {FormModel} from "../../models";
import billingActions from "../../../billingService/billing-service.action.json"

const Child: React.FC<FormModel & {selectionItems: any} & {onSelectionChangedHandler: any}> = memo(({ register, formState: { errors }, disabled, selectionItems, onSelectionChangedHandler}) => (
    <>
        <Select
            register={register}
            name="billingService"
            title="Billing Service:"
            data-testid="billing-service-test-id"
            labelClassName={classes.input_label}
            lightColor={true}
            disabled={disabled}
            hideSelection={false}
            onSelectionChangedHandler = {onSelectionChangedHandler}
            selectionItems = {selectionItems}
        />
    </>
));
const SelectBillingActionField: React.FC<any> = ({disabled = false, selectionEvent}) => {
    const methods = useFormContext();
    const onSelectionChangedHandler = (selectedOption) => {
        selectionEvent(selectedOption);
    };
    return <Child {...methods} disabled={disabled} selectionItems = {billingActions.billingActions} onSelectionChangedHandler = {onSelectionChangedHandler} />;
};
export default SelectBillingActionField;