import classes from "../../../../../components/form/components/modal/CreditsNoteModal.module.scss";
import React from "react";
import AuthGuard from "../../../../../shared/components/AuthGuard";
import { Box, Modal } from "@mui/material";


const MessageModal = ({
  header,
  message,
  closeModal,
}) => {
  return (
    <div
      data-testid="main-div-testid"
      className={classes.container}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.className === classes.container) closeModal();
      }}
    >
      <Modal open={true} onClose={closeModal}>
        <Box className={classes.box}>
        <b>{header}</b>
        <br/>
        {message}
        </Box>
      </Modal>
    </div>
  );
};

export default AuthGuard(MessageModal);
