import React, {memo} from "react";
import {Input} from "../FormComponents";
import classes from "./LandingPageFields/LandingPageFormFields.module.scss";
import {InputModel} from "../models";
import {useFormContext} from "react-hook-form";

const Child: React.FC<any> = memo(({ register, formState: { errors }, disabled }) => (
    <>
        <Input
            register={register}
            name="startDate"
            title="Enter Start Date:"
            placeholder="12/12/2022"
            maxLength={128}
            data-testid="startDate-testid"
            labelClassName={classes.input_label}
            errors={errors}
            lightColor={true}
            disabled={disabled}
            readOnly={false}
            inputType = 'date'
            maxValue = '9999-12-31'
        />
    </>
));

const StartDateField: React.FC<InputModel> = ({ disabled = false }) => {
    const methods = useFormContext();
    return <Child {...methods} disabled={disabled} />;
};
export default StartDateField;