import classes from "./CreditsNote.module.scss";
import React, {useState} from "react";
import authGuard from "../../../shared/components/AuthGuard";
import {CreditNote} from "../../../models/CreditNote";
import CreditsNoteModal from "../../form/components/modal/CreditsNoteModal";
import {currencyValueFormatter} from '../../../utils/CommonUtils'
import {DataGrid} from "@mui/x-data-grid";

const AddCreditNoteView = ({invoice, isEditable, handleOnSubmit, disableVirtualization}) => {
    const [modalOpen, setModalOpen] = useState(false);

    const columns = [
        {field: "stripeInvoiceNumber", headerName: "Stripe Invoice Number", width: 250},
        {field: "description", headerName: "Description", width: 500},
        {field: "amount", headerName: "Total", width: 150, valueFormatter: currencyValueFormatter},
        {field: "reason", headerName: "Reason", width: 300},
        {field: "billingCreditsNoteId", headerName: "Billing Credit Note ID", width: 300},
        {field: "billingInvoiceId", headerName: "Billing Invoice ID", width: 300}
    ];

    const getCreditNotesDisplayData = (creditNotes: CreditNote[]) => {
        return creditNotes.map(creditNote => {
            return {
                id: creditNote.id,
                stripeInvoiceNumber: invoice.metadata["stripeInvoiceNumber"],
                billingCreditsNoteId: creditNote.id,
                billingInvoiceId: creditNote.invoiceId,
                description: creditNote.description,
                amount: creditNote.amount,
                reason: creditNote.reason
            };
        });
    };

    return(
      <>
          <div className={classes.divAdd}>
              <button data-testid="add-credit-note-test-id" disabled={!isEditable} onClick={() => setModalOpen(!modalOpen)} className={`${classes.button} ${isEditable === true? classes.enable_bg_color : classes.disable_bg_color}`}>Add Credits Note</button>
          </div>
          {invoice.credits &&
              <div className={classes.table}>
                  <DataGrid
                      rows={getCreditNotesDisplayData(invoice.credits)}
                      columns={columns}
                      initialState={{
                          pagination: {
                              paginationModel: {page: 0, pageSize: 10},
                          },
                      }}
                      pageSizeOptions={[10, 20, 50]}
                      disableVirtualization={disableVirtualization}
                      disableColumnSelector
                  />
              </div>
          }
          {modalOpen && <CreditsNoteModal data-testid="add-creditsnote-modal" invoice={invoice}
                                          closeModal={() => setModalOpen(false)} onSubmit={handleOnSubmit}/>}
      </>
    );
}
export default authGuard(AddCreditNoteView);