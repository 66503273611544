import React, {useEffect} from 'react'
import {useNavigate} from "react-router-dom";
import {AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal} from "@azure/msal-react";
import {loginRequest} from "../../authConfig";
import classes from "./SignOnPage.module.scss";
import OpsHeader from "../header/OpsHeader";
import ButtonPrimary from "../../shared/components/ButtonPrimary";


function SignOnPage() {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/admin');
        }
    }, [isAuthenticated]);

    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        });
    }

    const handleLogout = () => {
        instance.logoutRedirect().catch(e => {
            console.error(e);
        });
    }

    return (
        <div>
            <OpsHeader/>
            <div className={classes.signOnPage}>
                <AuthenticatedTemplate>
                        <ButtonPrimary onClick={handleLogout} text={'Logout'} testId={'logout-button'}/>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                        <ButtonPrimary onClick={handleLogin} text={'Single Sign-On (SSO)'} testId={'login-button'}/>
                </UnauthenticatedTemplate>
            </div>
        </div>
    );
}

export default SignOnPage;
