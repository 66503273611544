import React, {useState} from "react";
import { currencyValueFormatter } from "../../../../../utils/CommonUtils";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import authGuard from "../../../../../shared/components/AuthGuard";
import { PaymentIntent } from "../../../../../models/PaymentIntent";
import MessageModal from "../message/MessageModal";

const PaymentIntentsTable = ({
  paymentIntents,
  refunds,
  onPaymentIntentSelection,
  classes,
  disableVirtualization,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const onRowSelection = (paymentIntent) => {
    const refundAmount =
      refunds
        ?.filter((refund) => refund.paymentIntentId === paymentIntent.id)
        .map((refund) => refund.amount)
        .reduce((sum, refund) => sum + refund, 0) || 0;

    if (paymentIntent.amount === refundAmount) {
      setModalOpen(true)
      return;
    }

    onPaymentIntentSelection(paymentIntent);
  };

  const columns: GridColDef[] = [
    {
      field: "stripeInvoiceNumber",
      headerName: "Stripe Invoice Number",
      width: 200,
    },
    {
      field: "amount",
      headerName: "Payment Amount",
      width: 175,
      valueFormatter: currencyValueFormatter,
    },
    {
      field: "refunds",
      headerName: "Refunds Applied",
      width: 175,
      valueFormatter: currencyValueFormatter,
    },
    { field: "invoiceStatus", headerName: "Invoice Status", width: 125 },
    { field: "description", headerName: "Payment Description", width: 250 },
    { field: "invoiceStartDate", headerName: "Invoice Start Date", width: 150 },
    { field: "invoiceDueDate", headerName: "Invoice Due Date", width: 150 },
    { field: "id", headerName: "Payment Intent ID", width: 250 },
    { field: "invoiceId", headerName: "Billing Invoice ID", width: 250 },
    {
      field: "invoiceExternalBillingId",
      headerName: "Stripe Invoice ID",
      width: 250,
    },
  ];

  const getPaymentIntentsDisplayData = (paymentIntents: PaymentIntent[]) => {
    return paymentIntents.map((paymentIntent) => {
      return {
        id: paymentIntent.id,
        amount: paymentIntent.amount,
        refunds:
          refunds
            ?.filter((refund) => refund.paymentIntentId === paymentIntent.id)
            .map((refund) => refund.amount)
            .reduce((sum, refund) => sum + refund, 0) || 0,
        stripeInvoiceNumber:
          paymentIntent.invoice.metadata["stripeInvoiceNumber"],
        invoiceStatus: paymentIntent.invoice.status,
        description: paymentIntent.description,
        invoiceStartDate: paymentIntent.invoice.period.start,
        invoiceDueDate: paymentIntent.invoice.dueDate,
        dueDate: paymentIntent.dueDate,
        invoiceId: paymentIntent.invoiceId,
        invoiceExternalBillingId: paymentIntent.invoice.externalBillingId,
      };
    });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      {modalOpen && <MessageModal header={"Cannot apply more refunds"} message={"Payment Intent is already fully refunded."} closeModal={closeModal}/>}
      {paymentIntents && (
        <div className={classes.table}>
          <DataGrid
            rows={getPaymentIntentsDisplayData(paymentIntents)}
            columns={columns}
            sortModel={[
              {
                field: "stripeInvoiceNumber",
                sort: "desc",
              },
            ]}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 50]}
            onRowSelectionModelChange={(ids) => {
              const paymentIntent = paymentIntents.find(
                (paymentIntent) => paymentIntent.id === ids[0],
              );
              onRowSelection(paymentIntent);
            }}
            disableVirtualization={disableVirtualization}
            disableColumnSelector
          />
        </div>
      )}
    </>
  );
};

export default authGuard(PaymentIntentsTable);
