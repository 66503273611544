import React, {FC} from 'react';
import ReactModal from "react-modal";
import './Modal.scss';

interface ModalProps {
    isOpen: boolean,
}

const Modal:FC<ModalProps> = ({children, isOpen}) => {
    return (
        <ReactModal className='common-modal' isOpen={isOpen} contentLabel="Modal" ariaHideApp={false}>
            <div className='common-modal-content' data-testid="react-modal">
                {children}
            </div>
        </ReactModal>
    );
};

export default Modal;
