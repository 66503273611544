import React from "react";
import {CircularProgress} from "@material-ui/core";

const Loader:React.FC = () => {
    return (
        <div style={{position:"absolute", display: 'flex', left:"50%", top:"50%"}}>
            <CircularProgress data-testid = 'loader' className={'circular-progress'} size={44}/>
        </div>
    );
};

export default Loader;
