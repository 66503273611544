import React, {useEffect, useState} from "react";
import InvoiceIdField from "./../../../form/components/CreditsNote/InvoiceIdField";
import authGuard from "../../../../shared/components/AuthGuard";
import {Button, Form} from "../../FormComponents";
import DescriptionField from "../CreditsNote/DescriptionField";
import {CreateCreditNoteRequest} from "../../../../models/CreateCreditNoteRequest";
import {addCreditsNote} from "../../../../services/BillingService";
import classes from '../modal/CreditsNoteModal.module.scss'
import AmountCurrencyField from "../AmountCurrencyField";
import {convertCurrencyToNumber} from "../../../../utils/CommonUtils";
import {Box, Modal} from "@mui/material";
const CreditsNoteModal = ({invoice, closeModal, onSubmit}) => {
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [allFieldsDirty, setAllFieldsDirty] = useState(false);
    const [reason, setReason] = useState(null)
    const [error, setError] = useState(null);
    const [defaultValues, setDefaultValues] = useState({
        invoiceId: invoice.id,
        description: null,
        amountCurrency: null,
        reason: null,
        metadata: {}
    });

    useEffect(() => {
        if (allFieldsDirty === enableSubmit) return;
        setEnableSubmit(allFieldsDirty);
    }, [allFieldsDirty]);

    async function onSubmitForm(data) {
        if (enableSubmit) return;
        setEnableSubmit(true);

        const creditNote = {
            invoiceId: data.invoiceId,
            description: data.description,
            amount: convertCurrencyToNumber(data.amountCurrency.toString()),
            reason: reason,
            metadata: {}
        }
        saveCreditsNote(creditNote).then((data) => {
            if (data.status !== 200) {
                setEnableSubmit(true);
                return;
            }
            onSubmit(creditNote);
            closeModal()
            setEnableSubmit(false);
        }).catch((ex) => {
            setError(`Add Credit Note failed : ${ex}`);
            setEnableSubmit(false);
        });
    }

    async function saveCreditsNote(creditsNote: CreateCreditNoteRequest) {
        console.log('saveCreditsNote =' + creditsNote.reason);
        return await addCreditsNote(creditsNote);
    }

    function onReasonSelection(value) {
        if (value !== '—Select Reason—')
            setReason(value);
    }

    return (
        <div data-testid="main-div-testid" className={classes.container} onClick={(e) => {
            const target = e.target as HTMLElement
            if (target.className === classes.container) closeModal()
        }}>
            <Modal open={true} onClose={closeModal}>
                <Box className={classes.box}>
                    <Form
                        onSubmit={onSubmitForm}
                        defaultValues={defaultValues}
                        allFieldsDirty={allFieldsDirty}
                        setAllFieldsDirty={setAllFieldsDirty}
                    >
                        <InvoiceIdField disabled={true}/>
                        <div className={classes.formGroup}>
                            <label htmlFor="reason">Select Reason:</label>
                            <select
                                required
                                id="reason"
                                data-testid="reason-test-id"
                                name="reason"
                                value={reason}
                                onChange={(event) => onReasonSelection(event.target.value)}
                            >
                                <optgroup label="Reasons">
                                    <option value="—Select Reason—">—Select Reason—</option>
                                    <option value="DUPLICATE">Duplicate</option>
                                    <option value="FRAUDULENT">Fraudulent</option>
                                    <option value="ORDER_CHANGE">Order Change</option>
                                    <option value="PRODUCT_UNSATISFACTORY">Product Unsatisfactory</option>
                                </optgroup>
                            </select>
                        </div>
                        <DescriptionField disabled={enableSubmit}/>
                        <AmountCurrencyField disabled={enableSubmit}/>
                        <Button text={'Submit'} enableSubmit={enableSubmit} className={classes.btn}/>
                    </Form>
                    <div hidden={!error} className={classes.deleteBtn}>
                        <p>{error}</p>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
export default authGuard(CreditsNoteModal);