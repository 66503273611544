import React, {memo} from "react";
import {InputCurrency} from "../FormComponents";
import classes from "./LandingPageFields/LandingPageFormFields.module.scss";
import {InputValueModel} from "../models";
import {useFormContext} from "react-hook-form";

const Child: React.FC<any> = memo(({ register, formState: { errors }, disabled, defaultValue }) => (
    <>
        <InputCurrency
            register={register}
            name="amountCurrency"
            title="Enter Amount:"
            placeholder="$0,000.00"
            maxLength={10}
            data-testid="amount-currency-input"
            labelClassName={classes.input_label}
            errors={errors}
            lightColor={true}
            disabled={disabled}
            readOnly={false}
            defaultValue = {defaultValue ?? null}
            decimalsLimit = {2}
            allowNegativeValue={false}
            rules={{
                required: {
                    value: true,
                    message: 'Amount is required',
                }
            }}
        />
    </>
));

const AmountCurrencyField: React.FC<InputValueModel> = ({ disabled = false, value = null }) => {
    const methods = useFormContext();
    return <Child {...methods} disabled={disabled} defaultValue={value} />;
};
export default AmountCurrencyField;