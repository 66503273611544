import React from "react";
import classes from "./ButtonPrimary.module.scss";

interface ButtonPrimaryProps {
    text: string,
    onClick: any,
    testId?: string,
    hide?: boolean
}

const ButtonPrimary:React.FC<ButtonPrimaryProps> = ({text, onClick, testId, hide}) => {
    return (
        <div style={{width: '344px', height: '56px', visibility: !hide? 'visible': 'collapse' }}>
            <button data-testid = {testId} className={classes.button_primary} onClick={() => onClick()}>{text}</button>
        </div>
    );
};

export default ButtonPrimary;
