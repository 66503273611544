import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {MsalProvider} from "@azure/msal-react";
import {msalInstance} from "./authConfig";
import SignOnPage from "./components/signOnPage/SignOnPage";
import Admin from "./components/admin/Admin";
import LandingPage from "./components/billingService/landingPage/LandingPage";
import Delinquents from "./components/delinquents/Delinquents";
import CreditReportPage from "./components/billingService/creditReport/CreditReportPage";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

const App = () => {
    return (
        <BrowserRouter>
            <MsalProvider instance={msalInstance}>
                <Routes>
                    <Route path="/admin" element={<Admin/>}/>
                    <Route path="/" element={<SignOnPage/>}>
                        <Route path="/:code" element={<SignOnPage/>}/>
                    </Route>
                    <Route path="/admin/billing-service/*" element={<LandingPage/>}/>
                    <Route path="/admin/delinquents/*" element={<Delinquents/>}/>
                    <Route path="/admin/credit-report" element={<CreditReportPage/>}/>
                </Routes>
            </MsalProvider>
        </BrowserRouter>
    )
};
export default App;
