import React, {memo} from "react";
import {InputCurrency} from "../FormComponents";
import classes from "./LandingPageFields/LandingPageFormFields.module.scss";
import {InputNumberModel} from "../models";
import {useFormContext} from "react-hook-form";

const Child: React.FC<any> = memo(({ register, allowNegativeValue = false, formState: { errors }, disabled }) => (
    <>
        <InputCurrency
            register={register}
            name="unitCost"
            title="Enter Unit Cost:"
            placeholder="$0,000.00"
            maxLength={128}
            data-testid="unitCost-testid"
            labelClassName={classes.input_label}
            errors={errors}
            lightColor={true}
            disabled={disabled}
            readOnly={false}
            defaultValue = {null}
            decimalsLimit = {2}
            allowNegativeValue={allowNegativeValue}
            rules={{
                required: {
                    value: true,
                    message: 'Unit Cost is required',
                }
            }}
        />
    </>
));

const UnitCostField: React.FC<InputNumberModel> = ({ disabled = false, allowNegativeValue = false }) => {
    const methods = useFormContext();
    return <Child {...methods} disabled={disabled} allowNegativeValue={allowNegativeValue} />;
};
export default UnitCostField;
