import authGuard from "../../../shared/components/AuthGuard";
import classes from "../creditsNote/CreditsNote.module.scss";
import React, { useEffect, useState } from "react";
import { InvoiceStatus } from "../../../models/InvoiceStatus";
import { Invoice } from "../../../models/Invoice";
import InvoiceTable from "../../form/components/invoice/InvoiceTable";
import * as billingService from "../../../services/BillingService";
import { voidInvoice } from "../../../services/BillingService";
import { useCustomerContext } from "../landingPage/useLandingPage";
import DialogModal from "../../form/components/modal/confirmation/DialogModal";

const VoidInvoicePage = ({ disableVirtualization }) => {
    const state = useCustomerContext();
    const [openInvoices, setOpenInvoices] = useState<Invoice[]>([]);
    const [openVoidModal, setOpenVoidModal] = useState(false);
    const [invoice, setInvoice] = useState<Invoice>(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        (async () => {
            await getAllInvoices();
        })();
    }, []);

    async function getAllInvoices() {
        try {
            const invoicesResponse = await billingService.getAllInvoicesByCustomerId(state.id);
            setOpenInvoices(invoicesResponse.data.filter((invoice) => invoice.status === InvoiceStatus.OPEN && !invoice.subscriptionId));
        } catch (err) {
            setError(`Could not retrieve invoices for billing Customer Id = ${state.id}`);
        }
    }

    const onInvoiceSelection = (invoiceSelected: Invoice) => {
        setInvoice(invoiceSelected);
        setOpenVoidModal(!openVoidModal);
    };

    async function confirmationAction() {
        try {
            await voidInvoice(invoice.id);
        } catch (err) {
            setError(err.response?.data?.errorMessage || err.message);
        }
        setOpenVoidModal(false);
        await getAllInvoices();
    }

    return (
        <div>
            <div className={classes.container}>
                {/*modify invoice table to take in input for choosing action icon*/}
                {openInvoices && (
                    <InvoiceTable
                        invoices={openInvoices}
                        onInvoiceSelection={onInvoiceSelection}
                        classes={classes}
                        disableVirtualization={disableVirtualization}
                    />
                )}
                {openVoidModal && invoice && (
                    <DialogModal
                        title="Void"
                        dialogTitle={`Void Invoice - ${invoice.id}`}
                        displayData={`Customer Name: ${state.displayName}
                                    Description: ${invoice.description}`}
                        confirmation={confirmationAction}
                        closeModal={() => setOpenVoidModal(false)}
                    />
                )}
                <div hidden={!error} className={classes.deleteBtn}>
                    <p>{error}</p>
                </div>
            </div>
        </div>
    );
};
export default authGuard(VoidInvoicePage);
