import {createContext, useContext, useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import actions from "../billing-service.action.json";
import * as billingService from '../../../services/BillingService'
import {Customer} from "../../../models/Customer";
import {
    getCustomerProfileByEmail,
    getPaymentMethodsByCustomerId,
    getSupplementalPlansByProduct
} from "../../../services/BillingService";
import {PaymentMethod} from "../../../models/PaymentMethod";
import {SupplementalPlan} from "../../../models/SupplementalPlan";

const CustomerContext = createContext(null);
export const useCustomerContext = () => {
    return useContext(CustomerContext);
};

export default function useLandingPage() {
    const [processing, setProcessing] = useState(false);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [allFieldsDirty, setAllFieldsDirty] = useState(false);
    const [error, setError] = useState(null);
    const [billingAction, setBillingAction] = useState('');
    const navigate = useNavigate();
    const [customer, setCustomer] = useState<Customer>(null);
    const [customerName, setCustomerName] = useState('');
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [supplementalPlans, setSupplementalPlans] = useState<SupplementalPlan[]>([]);
    const [defaultValues, setDefaultValues] = useState({
        billingCustomerId: '',
        inputEmail: 'example@domain.com',
        name:'',
        externalBillingId:'',
        subscriptionId:'',
        email:'',
        billingService:'',
        defaultPaymentMethodId : ''
    });

    useEffect(() => {
        if (allFieldsDirty === enableSubmit) return;
        setEnableSubmit(allFieldsDirty);
    }, [allFieldsDirty]);

    useEffect(() => {
        actions.billingActions.map(({value, page}) => {
            if (value === billingAction) {
                customer.email = defaultValues.inputEmail;
                navigate(page);
            }
        });
    }, [billingAction]);

    const CustomerContextProvider = ({ children }) => {
        return (
            <CustomerContext.Provider value={{...customer, displayName: customerName, paymentMethods: paymentMethods, supplementalPlans: supplementalPlans}}>
                {children}
            </CustomerContext.Provider>
        );
    };

    async function onSubmit(data) {
        setEnableSubmit(false);
        setProcessing(true);
        setError(null);
        try {
            const customer = await billingService.getBillingCustomerByEmailId(data.email);
            const customerProfileResponse = await getCustomerProfileByEmail(customer.data.externalReferenceId);
            const paymentMethodsResponse = await getPaymentMethodsByCustomerId(customer.data.id);
            const supplementalPlansResponse = await getSupplementalPlansByProduct(window.__RUNTIME_CONFIG__.PRODUCT_ID);
            setCustomer(customer.data);
            setCustomerName(customerProfileResponse.data.displayName);
            setPaymentMethods(paymentMethodsResponse.data);
            setSupplementalPlans(supplementalPlansResponse.data);
            setDefaultValues({
                billingService: '',
                inputEmail: data.email,
                billingCustomerId: customer.data.id,
                email: data.email,
                name: customer.data.name,
                externalBillingId: customer.data.externalBillingId,
                subscriptionId: customer.data.subscriptionId,
                defaultPaymentMethodId: customer.data.defaultPaymentMethodId
            });
            setEnableSubmit(true);
        } catch (ex) {
            setProcessing(false);
            setEnableSubmit(false);
            setError(ex.toString());
        }
        setProcessing(false);
    }
    function onBillingActionEventHandler(selectedOption) {
        setBillingAction(selectedOption);
    }

    return {
        defaultValues,
        onSubmit,
        enableSubmit,
        processing,
        allFieldsDirty,
        setAllFieldsDirty,
        setBillingAction,
        onBillingActionEventHandler,
        error,
        setError,
        customer,
        customerName,
        CustomerContextProvider
    };
}
