import authGuard from "../../../../../shared/components/AuthGuard";
import {Button, Form} from "../../../FormComponents";
import BillingCustomerIdField from "../../BillingCustomerIdField";
import DescriptionField from "../../CreditsNote/DescriptionField";
import classes from "../CreditsNoteModal.module.scss";
import React, {useEffect, useState} from "react";
import * as billingService from "../../../../../services/BillingService";
import {Currency} from "../../../../../models/Currency";
import {CreateOrphanInvoiceRequest} from "../../../../../models/CreateOrphanInvoiceRequest";
import DueDateField from "../../DueDateField";
import {Box, Modal} from "@mui/material";

const OrphanInvoiceModal = ({billingCustomerId, closeModal, onSubmit}) => {
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [allFieldsDirty, setAllFieldsDirty] = useState(false);
    const [error, setError] = useState(null);
    const [defaultValues, setDefaultValues] = useState({
        customerId: billingCustomerId,
        description: null,
        currency: Currency.USD,
        unitQuantity: null,
        dueDate: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        taxRates: null,
        items: [],
        metadata: {}
    });

    useEffect(() => {
        if (allFieldsDirty === enableSubmit) return;
        setEnableSubmit(allFieldsDirty);
    }, [allFieldsDirty]);

    async function onSubmitForm(data) {
        setError(null);
        if (enableSubmit) return;

        setEnableSubmit(true);
        const createOrphanInvoiceRequest = {
            customerId: billingCustomerId,
            description: data.description,
            currency: Currency.USD,
            dueDate: data.dueDate,
            taxRates: data.taxRates === null || data.taxRates.length === 0 ? [] : data.taxRates.split(','),
            items: data.items,
            excludePendingInvoiceItems: true,
            metadata: data.metadata
        }
        addOrphanInvoice(createOrphanInvoiceRequest).then((data) => {
            if (data.status !== 201) {
                setError(`Create new orphan Invoice failed with status = ${data.status}`);
                setEnableSubmit(true);
                return;
            }
            setEnableSubmit(false);
            onSubmit(data);
            closeModal()
        }).catch((data) => {
            setError(`Create new orphan Invoice failed : ${data}`);
            setEnableSubmit(true);
        });
    }

    async function addOrphanInvoice(createOrphanInvoiceRequest: CreateOrphanInvoiceRequest) {
        return await billingService.saveOrphanInvoice(createOrphanInvoiceRequest);
    }

    return (
        <div data-testid="main-div-testid" className={classes.container} onClick={(e) => {
            const target = e.target as HTMLElement
            if (target.className === classes.container) closeModal()
        }}>
            <Modal open={true} onClose={closeModal}>
                <Box className={classes.box}>
                    <Form
                        onSubmit={onSubmitForm}
                        defaultValues={defaultValues}
                        allFieldsDirty={allFieldsDirty}
                        setAllFieldsDirty={setAllFieldsDirty}
                    >
                        <BillingCustomerIdField disabled={true}/>
                        <DescriptionField disabled={enableSubmit}/>
                        <DueDateField disabled={enableSubmit}/>
                        <Button text={'Submit'} enableSubmit={enableSubmit} className={classes.btn}/>
                    </Form>
                    <div hidden={!error} className={classes.deleteBtn}>
                        <p>{error}</p>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default authGuard(OrphanInvoiceModal);
