import classes from "../../../../../components/form/components/modal/CreditsNoteModal.module.scss";
import DescriptionField from "../../CreditsNote/DescriptionField";
import AmountCurrencyField from "../../AmountCurrencyField";
import { Button, Form } from "../../../FormComponents";
import React, { useState } from "react";
import AuthGuard from "../../../../../shared/components/AuthGuard";
import { Box, Modal } from "@mui/material";
import InvoiceIdField from "../../InvoiceIdField";
import PaymentIntentIdField from "../../PaymentIntentIdField";
import { convertNumberToCurrency } from "../../../../../utils/CommonUtils";

const RefundModal = ({
  paymentIntent,
  onSubmitForm,
  enableSubmit,
  closeModal,
}) => {
  const [allFieldsDirty, setAllFieldsDirty] = useState(false);
  const [defaultValues] = useState({
    paymentIntentId: paymentIntent.id,
    invoiceId: paymentIntent.invoiceId,
    description: "",
    amountCurrency: "",
  });

  return (
    <div
      data-testid="main-div-testid"
      className={classes.container}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.className === classes.container) closeModal();
      }}
    >
      <Modal open={true} onClose={closeModal}>
        <Box className={classes.box}>
          <Form
            onSubmit={onSubmitForm}
            defaultValues={defaultValues}
            allFieldsDirty={allFieldsDirty}
            setAllFieldsDirty={setAllFieldsDirty}
          >
            <div className={classes.div}>
              <b>
                Payment Intent Amount:{" "}
                {convertNumberToCurrency(paymentIntent.amount)}
              </b>
              <PaymentIntentIdField disabled={true} />
              <InvoiceIdField disabled={true} />
              <DescriptionField disabled={enableSubmit} />
              <AmountCurrencyField disabled={enableSubmit} />
              <Button
                text={"Submit"}
                enableSubmit={enableSubmit}
                className={classes.btn}
              />
            </div>
          </Form>
        </Box>
      </Modal>
    </div>
  );
};

export default AuthGuard(RefundModal);
