import axiosInstance from "../axiosConfig";
import {VehicleItem} from "../models/VehicleItem";

export const swapVehicle = async (selectionModel: string, vehicle: VehicleItem) => {
    return await axiosInstance.patch(`${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/reservations/${selectionModel}/swap-vehicle`, vehicle);
}

export const getAllCreditReport = async () => {
    return await axiosInstance.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API_HOST}/v1/reservations/credit-report`);
}
