import React, {memo} from "react";
import {InputWithHandler} from "../FormComponents";
import classes from "./LandingPageFields/LandingPageFormFields.module.scss";
import {InputModelWithRulesWithValue} from "../models";
import {useFormContext} from "react-hook-form";

const Child: React.FC<any> = memo(({ register, formState: { errors }, disabled, defaultValue, value, onChangeHandler, rulesMandatory}) => (
    <>
        <InputWithHandler
            register={register}
            name="taxRates"
            title= "Enter Tax Rates:"
            placeholder=""
            maxLength={128}
            data-testid="taxRates-testid"
            labelClassName={classes.input_label}
            errors={errors}
            lightColor={true}
            disabled={disabled}
            readOnly={false}
            defaultValue={defaultValue}
            value={value}
            onChangeHandler={onChangeHandler}
            rulesMandatory = {rulesMandatory}
            rules={{
                required: {
                    value: {rulesMandatory},
                    message: 'Tax Rates is required',
                }
            }}
        />
    </>
));

const TaxRatesField: React.FC<InputModelWithRulesWithValue> = ({ disabled = false, value = null, onChangeHandler = null, rulesMandatory = true}) => {
    const methods = useFormContext();
    return <Child {...methods} disabled={disabled} value = {value} onChangeHandler={onChangeHandler} rulesMandatory = {rulesMandatory}/>;
};
export default TaxRatesField;
